import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, NameplateLabelsDocumentResponse } from "../../../types";
import { nameplateLabelQueryKey } from "../query-cache";

export const useGetNameplateLabelById = (nameplateLabelById: string) => {
  const queryKey = nameplateLabelQueryKey(nameplateLabelById);

  const queryOptions: UseQueryOptions<NameplateLabelsDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.NameplateLabels.getById(nameplateLabelById)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    nameplateLabel: data?.data,
    error
  };
};
