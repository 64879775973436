import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetNameplateTemplateDocumentResponse, HTTPError } from "../../../types";
import { templateByIdQueryKey } from "../query-cache";

export const useGetAssetNameplateTemplateById = (templateId: string) => {
  const queryKey = templateByIdQueryKey(templateId);

  const queryOptions: UseQueryOptions<AssetNameplateTemplateDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetNameplateTemplates.getById(templateId),
    enabled: Boolean(templateId)
  };

  const { isLoading, isSuccess, isError, data, error } =
    useQuery<AssetNameplateTemplateDocumentResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    template: data?.data,
    error
  };
};
