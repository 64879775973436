import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { Asset, HTTPError, AssetResponse } from "../../../types";

export const useCreateAsset = () => {
  const queryOptions: UseMutationOptions<AssetResponse, HTTPError, Partial<Asset>> = {
    mutationFn: (asset) => AcceleratorApi.Assets.createAsset(asset)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    createAsset: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
