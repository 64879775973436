import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, PeopleListResponse } from "../../..";
import { HTTPError } from "../../../types";
import { searchPeople } from "./query-cache";

/**
 * Search for people
 */
export const useSearchPeople = (searchTerm: string, siteId?: string) => {
  const queryOptions: UseQueryOptions<PeopleListResponse, HTTPError> = {
    queryKey: searchPeople(siteId, searchTerm),
    queryFn: () => CommonDataApi.People.get(searchTerm, siteId),
    enabled: searchTerm.length > 2
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    users: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
