import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetProperty, HTTPError, AssetPropertyDocumentResponse } from "../../../types";

export const useCreateAssetProperty = () => {
  const queryOptions: UseMutationOptions<
    AssetPropertyDocumentResponse,
    HTTPError,
    Partial<AssetProperty>
  > = {
    mutationFn: (asset) => AcceleratorApi.AssetProperties.create(asset)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createAssetProperty: mutateAsync,
    assetProperty: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
