import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError, PeopleListResponse } from "../../..";
import { groupMembersByGroupId } from "./query-cache";

/**
 * Hook to get members of a group
 */
export const useGetGroupMembers = (groupId: string) => {
  const queryOptions: UseQueryOptions<PeopleListResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Groups.getGroupMembers(groupId),
    queryKey: groupMembersByGroupId(groupId),
    enabled: Boolean(groupId)
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    members: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
