import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, TagWeightsListResponse } from "../../../types";
import { tagWeightsByIdQueryKey } from "../query-cache";

export const useSearchTagWeightsById = (tagWeightId: string) => {
  const queryKey = tagWeightsByIdQueryKey(tagWeightId);

  const queryOptions: UseQueryOptions<TagWeightsListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.TagWeights.searchTagWeightsById(tagWeightId),
    enabled: Boolean(tagWeightId)
  };

  const { isLoading, isSuccess, isError, data, error } =
    useQuery<TagWeightsListResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tagWeights: data?.data ? data.data[0] : null,
    error
  };
};
