import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

interface Props {
  tabState: number;
  tabs: string[];
  handleTabChange?: (event: React.SyntheticEvent, newTab: number) => void;
}

export const TabsSection = (props: Props) => {
  const { tabs, tabState, handleTabChange } = props;

  return (
    <Tabs
      value={tabState}
      onChange={handleTabChange}
      sx={{ borderBottom: 1, borderColor: "grey.A200" }}
    >
      {tabs && tabs.map((tab, i) => <Tab label={tab} key={i} />)}
    </Tabs>
  );
};

TabsSection.defaultProps = {
  handleTabChange: () => null
};
