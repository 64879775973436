import { useEffect, useMemo, useState } from "react";
import { DefaultValues, Path, Resolver, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormGroup } from "@mui/material";

import {
  DialogActionButton,
  Dialog,
  TextField,
  WithLoader,
  Select,
  Option
} from "@packages/theme-mui-v5";

import { MultiCellEditNotes, Note } from "./MultiCellEditNotes";

export type MultiCellEditFormField<TFormValues> = {
  label: string;
  name: Path<TFormValues>;
  placeholder?: string;
  type: string;
  options?: Option[];
};

export type MultiCellEditFormProps<TFormValues> = {
  defaultFormValues: DefaultValues<TFormValues>; // Optional default form initial values
  formFields: MultiCellEditFormField<TFormValues>[]; // Form fields definition array
  formValidationSchema?: yup.ObjectSchema<unknown>; // Form validation Yup schema
  isLoading: boolean; // Loading state flag
  notes?: Note[]; // Optional note array to display in the edit form
  onClose?: () => void; // Form close handler
  onSubmit: (values: TFormValues) => void; // Form submit handler
  title: string; // Title of the shown modal
};

/**
 * Generic form component for multi cell editing
 */
export const MultiCellEditForm = <TFormValues,>(props: MultiCellEditFormProps<TFormValues>) => {
  const {
    defaultFormValues,
    formFields,
    formValidationSchema,
    isLoading,
    notes,
    onClose,
    onSubmit,
    title
  } = props;

  const { control, getFieldState, getValues, handleSubmit, trigger, watch } = useForm<TFormValues>({
    defaultValues: defaultFormValues,
    resolver: yupResolver(formValidationSchema) as unknown as Resolver<TFormValues>
  });

  const [showAcknowledgement, setShowAcknowledgement] = useState<boolean>(false);

  const submitForm = handleSubmit(onSubmit);

  const actions = useMemo(() => {
    if (showAcknowledgement) {
      return [
        {
          text: "Confirm override",
          variant: "contained",
          action: () => {
            submitForm();
            setShowAcknowledgement(false);
          },
          disabled: isLoading
        },

        {
          text: "Cancel",
          color: "error",
          variant: "text",
          action: () => {
            setShowAcknowledgement(false);
          },
          disabled: isLoading
        }
      ] as DialogActionButton[];
    }

    return [
      {
        text: "Override",
        variant: "contained",
        action: async () => {
          // Trigger form validation
          const isFormValid = await trigger();

          if (isFormValid) {
            setShowAcknowledgement(true);
          }
        },
        disabled: isLoading
      },
      {
        text: "Cancel",
        variant: "outlined",
        action: onClose,
        disabled: isLoading
      }
    ] as DialogActionButton[];
  }, [showAcknowledgement, handleSubmit, isLoading, onClose, onSubmit]);

  const values = watch();

  useEffect(() => {
    // Force live field level validation on change
    formFields.forEach((formField) => {
      const fieldName = formField.name as Path<TFormValues>;

      const fieldValue = getValues(fieldName);
      const { isDirty, isTouched } = getFieldState(fieldName);

      if (isDirty || (isTouched && fieldValue === undefined)) {
        trigger(formField.name);
      }
    });
  }, [formFields, values]);

  return (
    <Dialog
      actions={actions}
      disableBackdropClick
      isDraggable
      maxWidth="sm"
      onClose={onClose}
      title={title}
    >
      <WithLoader loading={isLoading}>
        <MultiCellEditNotes notes={notes} />

        <FormGroup onSubmit={submitForm}>
          {formFields.map((field, index) => {
            switch (field.type) {
              case "select": {
                return (
                  <Select
                    key={index}
                    options={field.options}
                    labelKey="label"
                    valueKey="value"
                    name={field.name}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={control}
                  />
                );
              }

              default: {
                return (
                  <TextField
                    key={index}
                    name={field.name}
                    type={field.type}
                    label={field.label}
                    placeholder={field.placeholder}
                    control={control}
                  />
                );
              }
            }
          })}
        </FormGroup>
      </WithLoader>
    </Dialog>
  );
};

MultiCellEditForm.displayName = "MultiCellEditForm";
