import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AssetProperty, AssetPropertyDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi, useGetAssetPropertyById } from "../../..";
import { assetPropertyByIdQueryKey } from "../query-cache";

/**
 * Hook to update an AssetProperty
 */
export const useUpdateAssetPropertyById = (assetPropertyId: string) => {
  const queryClient = useQueryClient();
  const { assetProperty } = useGetAssetPropertyById(assetPropertyId);
  const queryOptions: UseMutationOptions<
    AssetPropertyDocumentResponse,
    HTTPError,
    Partial<AssetProperty>
  > = {
    mutationFn: (updates) =>
      AcceleratorApi.AssetProperties.patch(assetProperty.id, assetProperty, updates),
    onSuccess: (res) => {
      queryClient.setQueryData(assetPropertyByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetProperty: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAssetProperty: mutateAsync
  };
};
