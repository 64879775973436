import { UseMutationOptions, useMutation } from "react-query";
import { HTTPError, AssetNoteResponse, AssetNote } from "../../../types";
import { AcceleratorApi } from "../../..";

/**
 * Hook to update DataSource
 */
export const useUpdateAssetNote = () => {
  const queryOptions: UseMutationOptions<AssetNoteResponse, HTTPError, AssetNote> = {
    mutationFn: (notes: AssetNote) => AcceleratorApi.AssetNotes.update(notes)
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetNote: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAssetNote: mutateAsync
  };
};
