import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi, HTTPError, PeopleListResponse } from "../../..";
import { groupMembersByGroupId } from "./query-cache";

/**
 *  Hook to remove one or more members from a group
 */
export const useRemoveGroupMembers = (groupId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PeopleListResponse, HTTPError, string[]> = {
    mutationFn: (members: string[]) => CommonDataApi.Groups.removeGroupMembers(groupId, members),
    onSuccess: (_response, members) => {
      queryClient.setQueryData<PeopleListResponse | undefined>(
        groupMembersByGroupId(groupId),
        (prevState) => {
          if (!prevState) {
            return;
          }

          return {
            ...prevState,
            total: prevState.total - 1,
            data: prevState.data.filter((member) => !members.includes(member.personId))
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    removeGroupMembers: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
