import * as React from "react";
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { dayjs } from "@packages/utils";
import { useMediaQuery } from "../../hooks";
import { PickersActionBarAction } from "@mui/x-date-pickers";

interface Props {
  defaultValue: string;
  minDate?: string;
  maxDate?: string;
  label?: string;
  onChange: (date: string) => void;
  actions?: PickersActionBarAction[];
}

const DateSelection = (props: Props) => {
  const { defaultValue, minDate, onChange, maxDate, actions, label } = props;
  const [date, setDate] = React.useState<string>(defaultValue);
  const { smDown, smUp } = useMediaQuery();

  React.useEffect(() => {
    onChange(date);
  }, [date]);

  const incrementDay = (increment: number) => {
    const currentDate = dayjs(date).utc();
    const nextDate = currentDate.add(increment, "days");
    setDate(nextDate.format("YYYY-MM-DD"));
  };

  const [maxDay, maxDayFormatted] = React.useMemo(() => {
    if (!maxDate) return [];
    const today = dayjs(maxDate).utc();
    return [today.toDate(), today.format("YYYY-MM-DD")];
  }, [maxDate]);

  const minimumDate = React.useMemo(() => {
    return minDate ? dayjs(minDate).utc().toDate() : new Date();
  }, [minDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "middle",
          justifyContent: "space-between",
          width: smDown ? "100%" : undefined
        }}
      >
        {smUp && (
          <IconButton
            color="primary"
            aria-label="previous day"
            component="label"
            disabled={!date}
            onClick={() => incrementDay(-1)}
            sx={{
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
        )}

        <Box sx={{ width: "100%", minWidth: 205 }}>
          <DatePicker
            minDate={minimumDate}
            label={label}
            format="MMMM dd, yyyy"
            value={date ? dayjs(date).utc().toDate() : date}
            views={["year", "month", "day"]}
            maxDate={maxDay}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "8.5px 0px 8.5px 14px"
              }
            }}
            slotProps={
              actions?.length
                ? {
                    actionBar: {
                      actions
                    }
                  }
                : {}
            }
            onChange={(newValue) => {
              setDate(newValue ? dayjs(newValue).utc().format("YYYY-MM-DD") : null);
            }}
          />
        </Box>

        {smUp && (
          <IconButton
            color="primary"
            aria-label="next day"
            component="label"
            onClick={() => incrementDay(1)}
            disabled={!date || date >= maxDayFormatted}
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent"
              }
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        )}
      </Box>
    </LocalizationProvider>
  );
};

export default DateSelection;
