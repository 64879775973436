import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetPropertyDocumentResponse, HTTPError } from "../../../types";
import { assetPropertyByIdQueryKey } from "../query-cache";

export const useGetAssetPropertyById = (assetPropertyId: string) => {
  const queryKey = assetPropertyByIdQueryKey(assetPropertyId);

  const queryOptions: UseQueryOptions<AssetPropertyDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetProperties.getById(assetPropertyId),
    enabled: Boolean(assetPropertyId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetProperty: data?.data,
    error
  };
};
