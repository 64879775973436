import React from "react";
import { Control, Controller, Path } from "react-hook-form";
import {
  Box,
  Checkbox,
  CircularProgress,
  InputLabel,
  FormHelperText,
  CheckboxProps,
  FormControlLabel
} from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types/fields";

type IProps = Omit<CheckboxProps, "control">;

export type Option = { name: string; id: string | number; disabled?: boolean };

export type CheckboxGroupElementProps<T> = IProps & {
  name: Path<T>;
  control?: Control<T>;
  options?: Option[];
  loading?: boolean;
  label?: string;
  disabled?: boolean;
  sortOptions?: boolean;
};

const alphabeticalSorting = (a: Option, b: Option) => {
  return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
};

function CheckboxGroup<TFieldValues extends FieldValues>({
  name,
  control,
  options,
  label,
  loading,
  required,
  sortOptions = true
}: CheckboxGroupElementProps<TFieldValues>) {
  const composedOptions = React.useMemo(() => {
    return sortOptions ? options.sort(alphabeticalSorting) : options;
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { onChange, value, ...rest } = field;
        return (
          <Box sx={{ maxWidth: 200 }}>
            <InputLabel
              error={!!error?.message}
              required={required}
              shrink={true}
              sx={{
                fontWeight: 600,
                color: error?.message ? "#F24965" : "#333333"
              }}
            >
              {label}
              {loading && <CircularProgress sx={{ ml: 1 }} size={16} />}
            </InputLabel>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {composedOptions.map((option: Option, i: number) => {
                const checked = value.includes(option?.id);
                const handleChange = (event) => {
                  onChange(
                    event.target.checked
                      ? [...value, option.id]
                      : value.filter((id) => id !== option.id)
                  );
                };
                return (
                  <FormControlLabel
                    key={i}
                    sx={{
                      "&.MuiFormControlLabel-root": {
                        marginLeft: 0,
                        paddingTop: 1,
                        alignItems: "self-start"
                      },
                      "& .MuiFormControlLabel-label": {
                        fontSize: 14
                      },
                      "& .MuiCheckbox-root": {
                        padding: 0.5,
                        paddingLeft: 0,
                        paddingTop: 0
                      }
                    }}
                    control={
                      <Checkbox {...rest} size="small" onChange={handleChange} checked={checked} />
                    }
                    label={option.name}
                  />
                );
              })}
            </Box>
            {!!error?.message && <FormHelperText error={true}>{error?.message}</FormHelperText>}
          </Box>
        );
      }}
    />
  );
}

export default CheckboxGroup;
