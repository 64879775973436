import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, SubAreaResponse } from "../../../types";
import { subAreasByIdQueryKey } from "../query-cache";

export const useGetSubAreaById = (subAreaId: string) => {
  const queryKey = subAreasByIdQueryKey(subAreaId);

  const queryOptions: UseQueryOptions<SubAreaResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.SubAreas.getById(subAreaId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    subArea: data?.data,
    error
  };
};
