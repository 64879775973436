import * as React from "react";
import * as yup from "yup";
import { Box, FormGroup } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField, AutoComplete, ToggleButtonGroup } from "@packages/theme-mui-v5";
import { useForm } from "react-hook-form";
import { Permission, useGetPermissionsByApplicationId } from "@packages/service-api";
import useLocalState from "../hooks/useLocalState";

interface PermissionForm {
  permission?: Permission | Partial<Permission>;
  title?: string;
  onClose?: () => void;
  onSubmit: (permission: Permission) => void;
}

export type PermissionFormRef = {
  onSubmit: () => void;
};

const permissionSchema = yup.object().shape({
  permissionTypeId: yup.string(),
  name: yup.string().required("Name is required."),
  description: yup.string().required("Description is required."),
  module: yup.string().required("Module is required."),
  isEnabled: yup.boolean().required("Status is required.")
});

const PermissionForm = React.forwardRef<PermissionFormRef, PermissionForm>((props, ref) => {
  const { permission, onSubmit } = props;
  const { applicationId } = useLocalState();
  const { permissions, loading } = useGetPermissionsByApplicationId(applicationId);

  const { handleSubmit, control } = useForm<Partial<Permission>>({
    defaultValues: {
      ...permission
    },
    resolver: yupResolver(permissionSchema)
  });

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  const options = React.useMemo(() => {
    return [
      { label: "Active", value: true },
      { label: "InActive", value: false }
    ];
  }, [permission]);

  const permissionsOptions = React.useMemo(() => {
    return permissions.map((permission) => ({
      label: permission.name,
      value: permission.permissionTypeId,
      id: permission.permissionTypeId
    }));
  }, [permission]);

  return (
    <FormGroup onSubmit={handleSubmit(onSubmit)}>
      <TextField name="permissionTypeId" label="GUID" control={control} />
      <TextField name="name" label="Name" control={control} required />
      <TextField name="description" label="Description" control={control} required />
      <AutoComplete
        name="module"
        disabled={loading}
        label="Module"
        control={control}
        options={permissionsOptions}
        required
      />
      <Box pt={2}>
        <ToggleButtonGroup options={options} name="isEnabled" label="Status" control={control} />
      </Box>
    </FormGroup>
  );
});

PermissionForm.displayName = "PermissionForm";

export default PermissionForm;
