import { Permission } from "@packages/service-api";
import { TreeNode } from "../drawers/groups/EditGroupPermissions";
/**
 * Group by
 * @template T
 * @template K
 * @param list {T[]}
 * @param getKey {(item: T) => K}
 * @return {Record<K, T[]}
 */
const groupBy = <T, K extends keyof string>(list: T[], getKey: (item: T) => K) =>
  list.reduce((previous, currentItem) => {
    const group = getKey(currentItem);
    if (!previous[group]) previous[group] = [];
    previous[group].push(currentItem);
    return previous;
  }, {} as Record<K, T[]>);

/**
 * Created Permission config with pre selected permissions
 * @param array {Permission}
 * @param groupBy {string}
 * @param permission {Permission}
 * @return {TreeNode[]}
 */
const formatPermissionsData = (
  array: Permission[],
  key: string,
  permission: Permission[]
): TreeNode[] => {
  // Set group permission with permission config
  const updatedWithPermission = array.map((data) => ({
    ...data,
    selected: Boolean(
      permission.some((permission) => permission.permissionTypeId === data.permissionTypeId)
    )
  }));
  // Group by permission
  const groupedData = groupBy(updatedWithPermission, (i) => i[key]);
  const keys = Object.keys(groupedData).sort();
  return keys.map((key) => {
    const permission = groupedData[key]; // get all permission associated with module;
    const children = permission.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    const selectedChild = children.filter((item: TreeNode) => item.selected); // get selected child
    return {
      label: key,
      nodeId: key,
      parent: true,
      selected: !!(children.length && selectedChild.length === children.length), // parent check if all child selected than true
      indeterminate: !!(selectedChild.length && children.length > selectedChild.length), // parent check if partial child selected than true
      children: groupedData[key].map((data: Permission) => {
        return {
          ...data,
          parentId: key,
          label: data.name,
          nodeId: data.permissionTypeId
        };
      })
    };
  });
};

/**
 * Update Permission config on Parent change
 * @param permissions {TreeNode[]}
 * @param selected {boolean}
 * @param nodes {TreeNode}
 * @return {TreeNode[]}
 */
const onParentChange = (
  permissions: TreeNode[],
  selected: boolean,
  nodes: TreeNode
): TreeNode[] => {
  // if parent selected, apply selected action to all child
  return permissions.map((permission) => {
    if (permission.nodeId === nodes.nodeId) {
      return {
        ...permission,
        selected,
        indeterminate: false,
        children: permission.children.map((data) => ({ ...data, selected }))
      };
    }
    return permission;
  });
};

/**
 * Update Permission config on Child change
 * @param permissions {TreeNode[]}
 * @param selected {boolean}
 * @param nodes {TreeNode}
 * @return {TreeNode[]}
 */
const onChildChange = (permissions: TreeNode[], selected: boolean, nodes: TreeNode): TreeNode[] => {
  // child selected need to update parent checked/unchecked/indeterminate
  return permissions.map((permission) => {
    if (permission.nodeId === nodes.parentId) {
      // find parent
      const updatedChildren = permission.children.map((data) => {
        if (data.nodeId === nodes.nodeId) {
          // update child here
          return {
            ...data,
            selected
          };
        }
        return data;
      });
      const selectedChild = updatedChildren.filter((data) => data.selected); // get selected child
      return {
        ...permission,
        selected: selectedChild.length === permission.children.length, // see if all child selected
        indeterminate: !!(
          (selectedChild.length && selectedChild.length < permission.children.length) // if partial children selected
        ),
        children: updatedChildren
      };
    }
    return permission;
  });
};
/**
 * Get Added/Removed Permissions
 * @param newPermissionTree {TreeNode}
 * @param permissions {Permission}
 * @return {[string[], string[]]}
 */
const getAddedAndRemovedPermissions = (
  newPermissionTree: TreeNode[],
  permissions: Permission[]
): [string[], string[]] => {
  const addedPermissions: string[] = []; // filter permissions from group
  const removedPermissions: string[] = []; // filter permissions from group
  newPermissionTree.forEach((data) => {
    data.children.forEach((child) => {
      if (child.selected) {
        if (
          !permissions.length ||
          !permissions.some((permission) => permission.permissionTypeId === child.nodeId)
        ) {
          addedPermissions.push(child.nodeId);
        }
      } else if (permissions.some((permission) => permission.permissionTypeId === child.nodeId)) {
        removedPermissions.push(child.nodeId);
      }
    });
  });
  return [addedPermissions, removedPermissions];
};

export {
  groupBy,
  formatPermissionsData,
  onParentChange,
  onChildChange,
  getAddedAndRemovedPermissions
};
