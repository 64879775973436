import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, Plant, PlantDocumentResponse, PlantsListResponse } from "../../../types";
import { plantQueryKey, plantsListQueryKey } from "../query-cache";

export const useCreatePlant = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PlantDocumentResponse, HTTPError, Partial<Plant>> = {
    mutationFn: (plant) => AcceleratorApi.Plants.create(plant),
    onSuccess: (data: PlantDocumentResponse) => {
      queryClient.setQueryData<PlantsListResponse | undefined>(
        plantsListQueryKey(),
        (currentState: PlantsListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [data.data, ...currentState.data]
          };
        }
      );
      queryClient.setQueryData(plantQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createPlant: mutateAsync,
    dataSource: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
