import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@packages/theme-mui-v5";

import {
  DataGrid,
  ColDef,
  ICellRendererParams,
  IServerSideGetRowsParams,
  AgGridReactProps,
  RowActionsCell,
  CACHE_BLOCK_SIZE
} from "@packages/data-grid";

import { useUserProfile, CommonDataApi, People } from "@packages/service-api";
import useLocalState from "../hooks/useLocalState";

export let gridRef = React.createRef<AgGridReactProps<People>>();

const UsersTable = () => {
  const navigate = useNavigate();
  const { applicationId, siteId, selectedLocationId } = useLocalState();
  const { smUp } = useMediaQuery();
  const userProfile = useUserProfile();
  const isDenseMode = userProfile.preferences?.ui?.gridViewMode === "dense";
  const getFullName = (params) => {
    return `${params.data.firstName || ""} ${params.data.lastName || ""}`;
  };

  const columnDefs: ColDef[] = React.useMemo(() => {
    return [
      {
        field: "fullName",
        headerName: "Name",
        valueGetter: getFullName
      },
      ...(smUp ? [{ field: "email", headerName: "Email Address" }] : []),
      ...(smUp ? [{ field: "userName", headerName: "AD User ID" }] : []),
      {
        field: "personId",
        headerName: "",
        minWidth: 65,
        maxWidth: 65,
        cellClass: "align-center",
        cellRenderer: (params: ICellRendererParams) => (
          <RowActionsCell
            actions={[
              {
                icon: "groups",
                color: "primary",
                tooltip: "Manage user",
                action: () => navigate(`/admin/auth/users/${params.value}/manage-user`)
              }
            ]}
          />
        )
      }
    ];
  }, [smUp]);

  const dataSource = React.useMemo(() => {
    return {
      getRows: (params: IServerSideGetRowsParams) => {
        params.api.showLoadingOverlay();
        CommonDataApi.People.getByLocation(applicationId, siteId, selectedLocationId, {
          page: params.request.startRow,
          size: CACHE_BLOCK_SIZE
        })
          .then((data) => {
            params.success({ rowData: data.data, rowCount: data.total });
            params.api.hideOverlay();
          })
          .catch(() => {
            params.fail();
            params.api.hideOverlay();
          });
      }
    };
  }, [isDenseMode]);

  React.useEffect(() => {
    return () => {
      gridRef = null;
    };
  }, []);

  return (
    <DataGrid
      serverSideDatasource={dataSource}
      isServersidePagination
      gridRef={gridRef}
      columnDefs={columnDefs}
    />
  );
};

export default UsersTable;
