import React from "react";
import { Control, Controller, Path } from "react-hook-form";
import {
  Box,
  FormLabel,
  ToggleButtonGroupProps as MUIToggleButtonGroupProps,
  ToggleButton,
  ToggleButtonGroup as MUIToggleButtonGroup,
  FormControl
} from "@mui/material";
import { FieldValues } from "react-hook-form/dist/types/fields";

interface Options {
  label: React.ReactElement | string | React.ReactNode | JSX.Element;
  value: string | number | boolean;
}

type IProps = Omit<MUIToggleButtonGroupProps, "name">;

export type ToggleButtonGroupProps<T> = IProps & {
  options: Options[];
  name: Path<T>;
  label?: string;
  minWidth?: number;
  control?: Control<T>;
  required?: boolean;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

function ToggleButtonGroup<TFieldValues extends FieldValues>({
  name,
  minWidth = 90,
  label,
  options,
  control,
  required,
  disabled,
  onChange: propsOnChange
}: ToggleButtonGroupProps<TFieldValues>): JSX.Element {
  return (
    <FormControl>
      {label && (
        <FormLabel
          sx={(theme) => ({
            fontWeight: 600,
            fontSize: 12,
            pb: 1,
            color: theme.palette.text.primary
          })}
        >
          {label}
        </FormLabel>
      )}
      <Box>
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => {
            const handleChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
              if (disabled) return;

              if (required) {
                if (newValue !== null) {
                  onChange(newValue);
                  if (propsOnChange) {
                    propsOnChange(newValue);
                  }
                }
              } else {
                onChange(newValue);
                if (propsOnChange) {
                  propsOnChange(newValue);
                }
              }
            };
            return (
              <MUIToggleButtonGroup
                size="small"
                color="primary"
                value={value}
                exclusive
                onChange={handleChange}
              >
                {options.map((option, i) => (
                  <ToggleButton
                    sx={(theme) => ({ minWidth, color: theme.palette.text.secondary })}
                    value={option.value}
                    key={i}
                  >
                    {option.label}
                  </ToggleButton>
                ))}
              </MUIToggleButtonGroup>
            );
          }}
        />
      </Box>
    </FormControl>
  );
}

ToggleButtonGroup.defaultProps = {
  required: true
};

export default ToggleButtonGroup;
