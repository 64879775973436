import { UseQueryOptions, useQuery } from "react-query";

import { PeopleDocumentResponse, HTTPError } from "../../../types";
import { CommonDataApi } from "../../..";
import { peopleById } from "./query-cache";

/**
 * Hook to get a single person by ID
 */
export const useGetPersonById = (personId: string) => {
  const queryOptions: UseQueryOptions<PeopleDocumentResponse, HTTPError> = {
    queryFn: () => CommonDataApi.People.getPeopleById(personId),
    queryKey: peopleById(personId),
    enabled: !!peopleById
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery(queryOptions);

  return {
    person: data?.data,
    error: error,
    loading: isLoading,
    isFetching: isFetching,
    refetchSites: refetch
  };
};
