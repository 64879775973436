import { GridReadyEvent } from "ag-grid-community";
import { useCallback, useRef, useEffect } from "react";

// this implementation was adapted from the code example https://codesandbox.io/s/2s4cx
const useStickyHeader = (grid: GridReadyEvent | null, disableStickyHeader = false) => {
  const headerElementRef = useRef<HTMLElement>();
  const bodyElementRef = useRef<HTMLElement>();
  const stickyRef = useRef<boolean>(false);
  const originalStyles = useRef({ position: "", top: "", zIndex: "", width: "" });

  useEffect(() => {
    headerElementRef.current = document.querySelector(".ag-header");
    bodyElementRef.current = document.querySelector(".ag-body");

    const header: HTMLElement = headerElementRef.current;
    const box = header?.getBoundingClientRect();

    originalStyles.current.position = header?.style.position;
    originalStyles.current.top = header?.style.top;
    originalStyles.current.width = `${box?.width}px`;
    originalStyles.current.zIndex = header?.style.zIndex;

    const handleResize = () => {
      if (headerElementRef.current) {
        const parentBox = header.parentElement.getBoundingClientRect();

        const nextWidth = `${parentBox.width}px`;
        header.style.width = nextWidth;
        originalStyles.current.width = nextWidth;
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [grid]);

  const onScroll = useCallback(() => {
    if (disableStickyHeader) {
      return;
    }

    const header = headerElementRef.current;
    const body = bodyElementRef.current;
    if (!header || !body) return;

    let shouldStick = false;
    let shouldUnstick = false;

    if (!stickyRef.current) {
      shouldStick = header.getBoundingClientRect().top <= 0;
      if (shouldStick) stickyRef.current = true;
    } else {
      shouldUnstick = body.getBoundingClientRect().top - header.getBoundingClientRect().height > 0;
      if (shouldUnstick) stickyRef.current = false;
    }

    if (shouldStick) {
      header.style.position = "fixed";
      header.style.top = "64px";
      header.style.zIndex = "1100";
      header.style.width = originalStyles.current.width;
    }
    if (shouldUnstick) {
      const original = originalStyles.current;
      header.style.position = original.position;
      header.style.top = original.top;
      header.style.zIndex = original.zIndex;
    }
  }, [disableStickyHeader]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return stickyRef;
};

export default useStickyHeader;
