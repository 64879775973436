import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { DataSourceResponse, HTTPError } from "../../../types";
import { dataSourcesByIdQueryKey } from "../query-cache";

export const useGetDataSource = (dataSourceId: string) => {
  const queryKey = dataSourcesByIdQueryKey(dataSourceId);

  const queryOptions: UseQueryOptions<DataSourceResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.DataSource.getDataSourceById(dataSourceId),
    enabled: Boolean(dataSourceId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery<DataSourceResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    dataSource: data?.data,
    error
  };
};
