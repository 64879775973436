import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AlarmMode, AlarmModeDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi, useGetAlarmModeById } from "../../..";
import { alarmModeByIdQueryKey } from "../query-cache";

/**
 * Hook to update an AlarmMode
 */
export const useUpdateAlarmModeById = (AlarmModeId: string) => {
  const queryClient = useQueryClient();
  const { alarmMode } = useGetAlarmModeById(AlarmModeId);
  const queryOptions: UseMutationOptions<
    AlarmModeDocumentResponse,
    HTTPError,
    Partial<AlarmMode>
  > = {
    mutationFn: (updates) => AcceleratorApi.AlarmModes.patch(alarmMode.id, alarmMode, updates),
    onSuccess: (res) => {
      queryClient.setQueryData(alarmModeByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    alarmMode: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAlarmMode: mutateAsync
  };
};
