import { useQuery } from "react-query";
import { TungstenDataSourceListResponse } from "../../../types";
import { MaterialBalanceApi } from "../../..";

export const useTungstenDataSources = () => {
  const { data, error, isLoading } = useQuery<TungstenDataSourceListResponse, Error>(
    ["tungsten", "data-sources"],
    () => MaterialBalanceApi.Tungsten.DataSources.get()
  );

  return {
    dataSources: data ? data.data : [],
    error: error,
    loading: isLoading
  };
};
