import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";

export function useDeleteAssetTemplateMutation<
  Options extends UseMutationOptions<void, unknown, string>
>(options?: Omit<Options, "mutationFn">) {
  return useMutation({
    ...options,
    mutationFn: (templateId) => {
      return AcceleratorApi.Templates.delete(templateId);
    }
  });
}
