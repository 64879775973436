import { useQuery, UseQueryOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { SteadyStateSearchResponse } from "../../../types/accelerator/steady-state";

export function useSearchSteadyStatesQuery(
  options: UseQueryOptions<SteadyStateSearchResponse> = {}
) {
  return useQuery({
    queryFn: AcceleratorApi.SteadyState.search,
    ...options
  });
}
