import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, GroupDocumentResponse, HTTPError } from "../../..";
import { groupById } from "./query-cache";

/**
 * Hook to get a group by ID
 */
export const useGroupById = (groupId: string) => {
  const queryOptions: UseQueryOptions<GroupDocumentResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Groups.getOne(groupId),
    queryKey: groupById(groupId),
    enabled: !!groupId
  };

  const { data, isLoading } = useQuery<GroupDocumentResponse>(queryOptions);

  return {
    group: data ? data.data : undefined,
    loading: isLoading
  };
};
