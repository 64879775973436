import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi, HTTPError, PeopleListResponse } from "../../..";
import { groupMembersByGroupId } from "../groups/query-cache";

interface Variables {
  members: string[];
  groupIds: string[];
}

export const useRemoveUserFromGroups = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<PeopleListResponse[], HTTPError, Variables> = {
    mutationFn: ({ members, groupIds }) => {
      return Promise.all(
        groupIds.map((groupId) => {
          return CommonDataApi.Groups.removeGroupMembers(groupId, members);
        })
      );
    },
    onSuccess: (_response, variables) => {
      variables.groupIds.forEach((groupId) => {
        queryClient.invalidateQueries(groupMembersByGroupId(groupId));
      });
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    removeGroupMembers: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
