import { UseQueryOptions, useQuery } from "react-query";
import { AcceleratorApi } from "../../..";
import { AssetTemplateListResponse, HTTPError } from "../../../types";
import { tagMappingByIdQueryKey } from "../query-cache";

export const useGetTagMappingTemplates = (
  assetTypeId: string,
  assetSubTypeId: string,
  assetId: string
) => {
  const queryKey = tagMappingByIdQueryKey(assetTypeId, assetSubTypeId);
  const queryOptions: UseQueryOptions<AssetTemplateListResponse, HTTPError> = {
    queryKey,
    queryFn: () =>
      AcceleratorApi.TagMappings.search(null, { assetTypeId, assetSubTypeId, assetId }),
    enabled: Boolean(assetTypeId && assetSubTypeId)
  };

  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery(queryOptions);

  return {
    loading: isLoading,
    isFetching,
    isSuccess,
    isError,
    tagMappingTemplates: data?.data,
    error,
    refetchTagMappingTemplates: refetch
  };
};
