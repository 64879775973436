import { FC, ReactNode } from "react";
import {
  Box,
  CircularProgress,
  IconButton,
  IconButtonProps,
  SxProps,
  Theme,
  Tooltip
} from "@mui/material";

import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";
import { isEmpty } from "@packages/utils";

export type RowAction = IconButtonProps & {
  action?: () => void;
  component?: ReactNode;
  href?: string;
  icon?: ApplicationIconName | ReactNode;
  tooltip?: string;
};

type Props = {
  actions: RowAction[];
  pending?: boolean;
  sx?: SxProps<Theme>;
};

export const RowActionsCell: FC<Props> = (props: Props) => {
  const { actions, pending, sx } = props;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        height: "100%",
        gap: 1.5,
        ...sx
      }}
    >
      {pending && <CircularProgress color="secondary" size={18} sx={{ marginX: 1 }} />}
      {actions.map((actionButton, index) => {
        const { action, component, disabled, href, icon, tooltip, color, ...restProps } =
          actionButton;

        if (component) {
          return component;
        }

        if (isEmpty(href)) {
          return (
            <Tooltip title={tooltip} key={index}>
              <span>
                <IconButton
                  color={color ?? "info"}
                  disabled={disabled}
                  size="small"
                  onClick={action}
                  {...restProps}
                >
                  {typeof icon === "string" ? (
                    <ApplicationIcon name={icon as ApplicationIconName} />
                  ) : (
                    icon
                  )}
                </IconButton>
              </span>
            </Tooltip>
          );
        } else {
          return (
            <IconButton
              key={index}
              color={color ?? "info"}
              component="a"
              download={href}
              href={href}
              size="small"
              target="_blank"
            >
              <ApplicationIcon name="download" />
            </IconButton>
          );
        }
      })}
    </Box>
  );
};
