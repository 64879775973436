import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AcceleratorSitesListResponse } from "../../../types";
import { sitesListQueryKey } from "../query-cache";

export const useGetAcceleratorSites = ({ enabled }: { enabled?: boolean } = {}) => {
  const queryKey = sitesListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AcceleratorSitesListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.Sites.get(),
      enabled
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    sites: data?.data || [],
    error,
    refetch
  };
};
