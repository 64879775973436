import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import {
  CommonDataApi,
  HTTPError,
  Permission,
  PermissionDocumentResponse,
  PermissionListResponse
} from "../../..";

import { permissionsList, permissionsOfApplication } from "./query-cache";

/**
 * Create a new permission
 */
export const useCreatePermission = (applicationId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PermissionDocumentResponse, HTTPError, Permission> = {
    mutationFn: (permission: Permission) =>
      CommonDataApi.Permissions.create({ ...permission, applicationId }),
    onSuccess: (response) => {
      queryClient.setQueryData<PermissionListResponse>(
        permissionsOfApplication(applicationId),
        (prevState: PermissionListResponse) => {
          if (!prevState) return undefined;
          return {
            ...prevState,
            total: prevState.total + 1,
            data: [...prevState.data, response.data]
          };
        }
      );

      queryClient.setQueryData<PermissionListResponse>(
        permissionsList(),
        (prevState: PermissionListResponse) => {
          if (!prevState) return undefined;
          return {
            ...prevState,
            total: prevState.total + 1,
            data: [...prevState.data, response.data]
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    permissions: data,
    createPermission: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
