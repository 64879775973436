import { UseMutationOptions, useMutation } from "react-query";
import { HTTPError, AssetPropertyNoteResponse, AssetPropertyNote } from "../../../types";
import { AcceleratorApi } from "../../..";

/**
 * Hook to update DataSource
 */
export const useUpdateAssetPropertyNote = () => {
  const queryOptions: UseMutationOptions<AssetPropertyNoteResponse, HTTPError, AssetPropertyNote> =
    {
      mutationFn: (notes: AssetPropertyNote) => AcceleratorApi.AssetPropertyNotesApi.update(notes)
    };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetPropertyNote: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateAssetPropertyNote: mutateAsync
  };
};
