import { useQuery } from "react-query";
import { groupByLocationId } from "./query-cache";
import { CommonDataApi, GroupListResponse } from "../../..";

function selectSortedByGroupName(response: GroupListResponse) {
  if (!response) {
    return undefined;
  }

  return {
    ...response,
    data: response.data.sort((a, b) =>
      a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0
    )
  };
}

/**
 *  Hook to get groups at a location
 */
export const useGetGroupsByLocationId = (applicationId: string, locationId: string) => {
  return useQuery<GroupListResponse>({
    queryFn: () => CommonDataApi.Groups.getByLocationId(applicationId, locationId),
    queryKey: groupByLocationId(locationId),
    select: selectSortedByGroupName,
    enabled: !!locationId
  });
};
