import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { assetPersonaTemplateByIdQueryKey } from "../query-cache";

export const useGetAssetPersonaTemplateById = (templateId?: string) => {
  return useQuery({
    queryKey: assetPersonaTemplateByIdQueryKey(templateId),
    queryFn: () => AcceleratorApi.Templates.getPersonaTemplateById(templateId),
    enabled: Boolean(templateId)
  });
};
