import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetResponse, HTTPError } from "../../../types";
import { assetByIdQueryKey } from "../query-cache";

export const useGetAsset = (assetId: string) => {
  const queryKey = assetByIdQueryKey(assetId);

  const queryOptions: UseQueryOptions<AssetResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.Assets.getAssetById(assetId),
    enabled: Boolean(assetId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery<AssetResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    asset: data?.data,
    error
  };
};
