import { Checkbox, FormControlLabel, Grid, Button, IconButton } from "@mui/material";
import { justifyContent, Action } from "./types";
import { Suspense } from "react";

interface Props {
  actions: Action[];
  justifyContent: justifyContent;
}

export const ToolbarActions = (props: Props) => {
  const { actions, justifyContent } = props;

  const renderAction = (action: Action) => {
    switch (action.type) {
      case "button":
        return (
          <Button
            variant={action.variant}
            onClick={() => action.onClick()}
            startIcon={action.startIcon}
            disabled={Boolean(action.disabled)}
          >
            {action.title}
          </Button>
        );
      case "icon":
        return (
          <IconButton color="primary" component="span">
            <Suspense>{action.icon}</Suspense>
          </IconButton>
        );
      case "checkbox":
        return (
          <FormControlLabel
            label={action.title}
            control={
              <Checkbox
                checked={!!action.inputValue}
                onChange={(e) => action.onInputChange(e.target.value)}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        );
      case "component":
        return action.element;
    }
  };

  return (
    <Grid container direction="row" justifyContent={justifyContent} alignItems="center" spacing={2}>
      {actions &&
        actions.map((action, i) => (
          <Grid item key={i}>
            {renderAction(action)}
          </Grid>
        ))}
    </Grid>
  );
};
