/* eslint-disable */
import { Box, CircularProgress } from "@mui/material";
import { Suspense, FunctionComponent } from "react";

export const SpinningLoader = (Component: FunctionComponent) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  );
