import { useCallback, useMemo, useState } from "react";
import { useTheme } from "@mui/material";

import { generateColorPalette } from "@packages/theme-mui-v5";
import { debug } from "@packages/utils";

import { AgGridReactProps, CellClassParams } from "..";
import {
  createRangeSelectionChangeHandler,
  ColumnID,
  GroupID,
  RowID,
  GroupedSelectionMap,
  SelectionMap,
  SelectionDefinition
} from ".";

export const useGridMultiCellEditing = <TData>({
  selectionDefinition,
  idKey
}: {
  selectionDefinition: SelectionDefinition<TData>;
  idKey: ColumnID<TData>; // Property to use for uniquely identifying a row in the grid.
}) => {
  const theme = useTheme();

  // Validate editable column groups object. No overlaps are allowed between groups!
  const allSelectionColumns = Object.values(selectionDefinition).flat();
  const uniqueSelectionColumns = new Set(allSelectionColumns);

  if (allSelectionColumns.length !== uniqueSelectionColumns.size) {
    throw new Error(
      `Invalid selection groups/columns definition object. Some column(n) has/have been declared in more than one group:\n${debug(
        selectionDefinition
      )}`
    );
  }

  const getRowId = useCallback((params) => {
    return params.data[idKey];
  }, []);

  const [rangeSelection, setRangeSelection] = useState<GroupedSelectionMap<TData>>({});

  const [selectedGroupsCount, selectedCellsCount, selectedGroup] = useMemo<
    [number, number, string | null]
  >(() => {
    const selectedGroupsCount = Object.keys(rangeSelection).length;

    let selectedCellsCount = 0;

    Object.values(rangeSelection).forEach((selectionMap: SelectionMap<TData>) => {
      selectedCellsCount += Object.values(selectionMap).reduce<number>(
        (aggregate: number, columnIds: ColumnID<TData>[]) => aggregate + columnIds.length,
        0
      );
    });

    let selectedGroup = null;

    if (selectedGroupsCount === 1) {
      selectedGroup = Object.keys(rangeSelection)?.[0] ?? null;
    }

    return [selectedGroupsCount, selectedCellsCount, selectedGroup];
  }, [rangeSelection]);

  const colorsPalette = useMemo<Record<GroupID, string>>(() => {
    const palette = generateColorPalette(Object.keys(selectionDefinition).length);

    return Object.keys(selectionDefinition).reduce((aggregate, groupId, index) => {
      aggregate[groupId] = palette[index];
      return aggregate;
    }, {});
  }, []);

  const columnGroupMapping = useMemo<Record<ColumnID<TData>, GroupID>>(() => {
    const mapping = {} as Record<ColumnID<TData>, GroupID>;

    Object.entries(selectionDefinition).forEach(([groupId, columnIds]) => {
      columnIds.forEach((columnId) => {
        mapping[columnId] = groupId;
      });
    });

    return mapping;
  }, [selectionDefinition]);

  const rangeSelectedCellStyle = useCallback(
    (params: CellClassParams<TData>) => {
      const rowId = getRowId(params) as RowID;

      const columnId = params.column.getColId() as ColumnID<TData>;

      const groupId = columnGroupMapping[columnId] as GroupID;

      const isSelectedCell = (rangeSelection?.[groupId]?.[rowId] ?? []).includes(columnId);

      return isSelectedCell
        ? {
            border: `1px solid ${theme.palette.grey["50"]}`,
            backgroundColor: "transparent"
          }
        : {};
    },
    [columnGroupMapping, rangeSelection, colorsPalette]
  );

  const clearRangeSelection = useCallback(
    ({ agGridRef }: { agGridRef: AgGridReactProps<TData> }) => {
      setRangeSelection({});

      agGridRef.api.clearRangeSelection();
    },
    []
  );

  const onRangeSelectionChanged = useCallback(
    createRangeSelectionChangeHandler<TData>({
      columnGroupMapping,
      setRangeSelection
    }),
    [columnGroupMapping]
  );

  const updateRangeSelectionCells = useCallback(
    ({
      agGridRef,
      rangeSelection,
      value
    }: {
      agGridRef: AgGridReactProps<TData>;
      rangeSelection: GroupedSelectionMap<TData>;
      value: unknown;
    }) => {
      Object.entries(rangeSelection.thresholds).map(([id, columns]) => {
        const row = agGridRef?.api.getRowNode(id);
        columns.forEach((column) => row.setDataValue(String(column), value));
      });
    },
    []
  );

  return {
    clearRangeSelection,
    getRowId,
    onRangeSelectionChanged,
    rangeSelection,
    rangeSelectedCellStyle,
    selectedCellsCount,
    selectedGroupsCount,
    selectedGroup,
    updateRangeSelectionCells
  };
};
