import { useNavigate, useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import { useDeletePermission, useGetPermissionById } from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const DeletePermission = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams<{ id: string }>();
  const { permission, loading } = useGetPermissionById(id);
  const { applicationId, selectedLocationId } = useLocalState();
  const { deletePermission, loading: deleteLoading } = useDeletePermission(
    applicationId,
    selectedLocationId,
    id
  );
  const navigate = useNavigate();

  const onClose = () => {
    navigate("/admin/auth/permissions");
  };

  const handleSave = async () => {
    try {
      await deletePermission();

      enqueueSnackbar("Permission deleted successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <Drawer
      title="Delete Permission"
      onClose={onClose}
      loading={loading || deleteLoading}
      actions={[
        { text: "Delete", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <WithLoader loading={loading || deleteLoading}>
        {!!permission?.name && (
          <div>
            Are you sure you want to delete <b>{`${permission?.name}`}</b> permission?
          </div>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default DeletePermission;
