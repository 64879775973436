import { Box, Divider, Paper, Typography } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode | React.ReactNode[];
  loading?: boolean;
  title: React.ReactElement | string | React.ReactNode | JSX.Element;
};

const DrawerContentSection: React.FC<Props> = (props) => {
  const { children, loading, title } = props;
  return (
    <Paper sx={{ ...(loading && { opacity: 0.5 }) }}>
      <Box p={2}>
        <Typography color="tertiary.dark" variant="h6">
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  );
};

export default DrawerContentSection;
