import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Paper, Box } from "@mui/material";
import GroupsTable from "./GroupsTable";
import { useUserProfile } from "@packages/service-api";
import { TabsSection, TabPanel, PageContainer } from "@packages/theme-mui-v5";
import LandingPageToolbar from "../components/LandingPageToolbar";
import PermissionsTable from "./PermissionsTable";
import UsersTable from "./UsersTable";
import useLocalState from "../hooks/useLocalState";

interface Props {
  activeTab: "users" | "groups" | "permissions";
}

const LandingMainPage = (props: Props) => {
  const navigate = useNavigate();
  const userProfile = useUserProfile();
  const { activeTab } = props;
  const { selectedLocationId } = useLocalState();

  const tabConfig = React.useMemo(() => {
    const config = [
      {
        label: "Groups",
        value: "groups",
        TabComponent: GroupsTable,
        index: 0,
        show: true
      },
      {
        label: "Permissions",
        value: "permissions",
        TabComponent: PermissionsTable,
        index: 1,
        show: userProfile.isSysAdmin
      },
      {
        label: "Users",
        value: "users",
        TabComponent: UsersTable,
        index: 2,
        show: true
      }
    ];
    return config.filter((item) => item.show).map((data, i) => ({ ...data, index: i }));
  }, [userProfile]);

  const tabs = React.useMemo(() => {
    return tabConfig.map((item) => item.label);
  }, [tabConfig]);

  const [activeIndex, activeIndexValue] = React.useMemo(() => {
    const activeTabData = tabConfig.find((item) => item.value === activeTab);
    return [activeTabData?.index, activeTabData?.value];
  }, [activeTab, tabConfig]);

  const handleTabChange = (_event: React.SyntheticEvent, newTab: number) => {
    const route = tabConfig.find((item) => item.index === newTab);
    navigate(`/admin/auth/${route.value}`);
  };

  React.useEffect(() => {
    if (activeIndex === undefined || activeIndex === null) {
      navigate(`/admin/auth/${tabConfig[0].value}`);
    }
  }, [activeIndex]);

  return (
    <>
      {!!selectedLocationId && <LandingPageToolbar tab={activeIndexValue} />}
      {!!selectedLocationId && (
        <Paper>
          <Box pt={2} pb={2}>
            <TabsSection tabState={activeIndex} tabs={tabs} handleTabChange={handleTabChange} />
          </Box>
          {tabConfig.map(({ TabComponent, index }) => (
            <TabPanel key={index} value={activeIndex} index={index}>
              <TabComponent key={selectedLocationId} />
            </TabPanel>
          ))}
        </Paper>
      )}
      <Outlet />
    </>
  );
};

const LandingPage = (props: Props) => {
  const { hidePageContainer } = useLocalState();

  return hidePageContainer ? (
    <LandingMainPage {...props} />
  ) : (
    <PageContainer maxWidth="xl" title="Admin">
      <LandingMainPage {...props} />
    </PageContainer>
  );
};

export default LandingPage;
