import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import {
  FormGroup,
  Typography,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  Paper,
  Divider
} from "@mui/material";
import {
  People,
  useAddGroupMembers,
  useGetGroupMembers,
  useGroupById,
  useRemoveGroupMembers
} from "@packages/service-api";
import AutoComplete from "../../components/AutoComplete";

const ManageGroupMembers = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams<{ id: string }>();
  const { group } = useGroupById(id);
  const [selected, updateSelected] = React.useState<People[]>([]);
  const [clearAutoComplete, setClearAutoComplete] = React.useState<string>(
    `autoComplete-${Math.floor(Math.random() * 1000000000)}`
  );
  const {
    addGroupMembers,
    loading: loadingAddGroupMembers,
    isSuccess: addGroupMembersSuccess
  } = useAddGroupMembers(id);

  const { members, loading } = useGetGroupMembers(id);
  const {
    removeGroupMembers,
    loading: loadingRemoveGroupMembers,
    isSuccess: removeGroupMembersSuccess
  } = useRemoveGroupMembers(id);

  const [selectedMembers, updateSelectedMembers] = React.useState<string[]>([]);

  const onGroupSelection = (checked: boolean, item: string) => {
    if (checked) {
      updateSelectedMembers([...selectedMembers, item]);
    } else {
      const selected = selectedMembers.filter((personId) => personId !== item);
      updateSelectedMembers(selected);
    }
  };

  const onRemoveGroupMembers = async () => {
    try {
      await removeGroupMembers(selectedMembers);

      enqueueSnackbar("Group members updated successfully.", {
        variant: "success"
      });
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  React.useEffect(() => {
    if (addGroupMembersSuccess) {
      setClearAutoComplete(`autoComplete-${Math.floor(Math.random() * 1000000000)}`);
      updateSelected([]);
    }
  }, [addGroupMembersSuccess]);

  React.useEffect(() => {
    if (removeGroupMembersSuccess) {
      updateSelectedMembers([]);
    }
  }, [removeGroupMembersSuccess]);

  const onClose = () => {
    navigate("/admin/auth/groups");
  };

  const onChange = (data: People[]) => {
    updateSelected(data);
  };

  const onAddGroupMembers = async () => {
    try {
      const members = selected.map((data: People) => data.personId);
      await addGroupMembers(members);

      enqueueSnackbar("Group members updated successfully.", {
        variant: "success"
      });
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const groupedUsers = React.useMemo(() => {
    type GroupedUsers = {
      application: People[];
      user: People[];
    };

    return members.reduce<GroupedUsers>(
      (grouped, member) => {
        if (member.isApplicationUser) {
          grouped.application.push(member);
        } else {
          grouped.user.push(member);
        }

        return grouped;
      },
      { application: [], user: [] }
    );
  }, [members]);

  const renderMemberList = (members: People[]) => {
    if (!members.length) {
      return <Typography sx={{ my: 1 }}>None</Typography>;
    }

    const sortedMembers = members.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
      return nameA.localeCompare(nameB);
    });

    return (
      <FormGroup>
        {sortedMembers.map((data: People) => {
          const checked = !!selectedMembers.find((personId: string) => personId === data.personId);
          return (
            <FormControlLabel
              label={`${data.firstName} ${data.lastName} (${data.userName})`}
              key={`item-${data.personId}`}
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => onGroupSelection(!checked, data.personId)}
                />
              }
            />
          );
        })}
      </FormGroup>
    );
  };

  return (
    <Drawer
      title={`Manage Group Members | ${group?.groupName || ""}`}
      onClose={onClose}
      loading={loading || loadingAddGroupMembers || loadingRemoveGroupMembers}
      actions={[{ text: "" }, { text: "Cancel", action: onClose }]}
    >
      <WithLoader loading={loading || loadingAddGroupMembers || loadingRemoveGroupMembers}>
        <Paper>
          <Box p={2}>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography color="tertiary.dark" variant="h6">
                Add Users
              </Typography>
              <Button
                onClick={onAddGroupMembers}
                disabled={!selected.length || loadingAddGroupMembers}
                variant="contained"
              >
                {`Add ${selected.length ? `(${selected.length}) ` : ""}Users`}
              </Button>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ p: 2 }}>
            <FormGroup>
              <Typography variant="subtitle2">Search users</Typography>
              <AutoComplete key={clearAutoComplete} onChange={onChange} />
            </FormGroup>
          </Box>
        </Paper>
        <Box sx={{ p: 2 }} />
        <Paper>
          <Box sx={{ p: 2 }}>
            <Box>
              {members.length ? (
                <Box>
                  <Typography variant="caption" sx={{ my: 2 }}>
                    <b>Applications</b>
                  </Typography>
                  {renderMemberList(groupedUsers.application)}

                  <Typography variant="caption" sx={{ my: 2 }}>
                    <b>Users</b>
                  </Typography>
                  {renderMemberList(groupedUsers.user)}
                </Box>
              ) : (
                !loading && <Box>No users found.</Box>
              )}
            </Box>
            {!!selectedMembers.length && (
              <Box pt={2}>
                <Divider />
                <Box sx={{ pt: 2 }} />
                <Box
                  sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <Button
                    onClick={onRemoveGroupMembers}
                    disabled={!selectedMembers.length || loadingRemoveGroupMembers}
                    variant="contained"
                  >
                    {`Remove ${selectedMembers.length ? `(${selectedMembers.length}) ` : ""}Users`}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Paper>
      </WithLoader>
    </Drawer>
  );
};

export default ManageGroupMembers;
