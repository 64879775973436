import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteTag = () => {
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (tagId: string) => AcceleratorApi.Tags.delete(tagId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteTag: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
