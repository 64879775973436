import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, PlantDocumentResponse } from "../../../types";
import { plantQueryKey } from "../query-cache";

export const useGetPlantById = (plantById: string) => {
  const queryKey = plantQueryKey(plantById);

  const queryOptions: UseQueryOptions<PlantDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.Plants.getById(plantById)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    plant: data?.data,
    error
  };
};
