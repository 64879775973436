import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, TagNotificationListResponse } from "../../../types";
import { tagNotificationsListQueryKey } from "../query-cache";

export const useGetTagNotifications = () => {
  const queryKey = tagNotificationsListQueryKey();

  const queryOptions: UseQueryOptions<TagNotificationListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.TagNotifications.get()
  };

  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tagNotifications: data?.data,
    error,
    isFetching,
    refetch
  };
};
