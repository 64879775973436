import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AssetNameplateData, AssetNameplateDataDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { assetNameplateDataListQueryKey } from "../query-cache";

type Variables = {
  previousAssetNameplateData: AssetNameplateData;
  assetNameplateData: Partial<AssetNameplateData>;
};

/**
 * Hook to update an AssetNameplateData
 */
export const useUpdateAssetNameplateData = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<AssetNameplateDataDocumentResponse, HTTPError, Variables> =
    {
      mutationFn: ({ previousAssetNameplateData, assetNameplateData }: Variables) =>
        AcceleratorApi.AssetNameplateData.update(previousAssetNameplateData, assetNameplateData),
      onSuccess: (res) => {
        queryClient.setQueryData(assetNameplateDataListQueryKey(res.data.id), () => res);
      }
    };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    assetNameplateData: data?.data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateAssetNameplateData: mutateAsync
  };
};
