import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  ProductionUnit,
  ProductionUnitListResponse,
  ProductionUnitResponse,
  HTTPError
} from "../../../types";

import { AcceleratorApi } from "../../..";
import { productionUnitsListQueryKey, productionUnitsQueryKey } from "../query-cache";

interface Variables {
  previousProductionUnit: ProductionUnit;
  productionUnit: Partial<ProductionUnit>;
}

export const useUpdateProductionUnit = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<ProductionUnitResponse, HTTPError, Variables> = {
    mutationFn: ({ previousProductionUnit, productionUnit }: Variables) =>
      AcceleratorApi.ProductionUnits.update(previousProductionUnit, productionUnit),
    onSuccess: (data: ProductionUnitResponse) => {
      queryClient.setQueryData<ProductionUnitListResponse | undefined>(
        productionUnitsListQueryKey(),
        (currentState: ProductionUnitListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => (state.id === data.data.id ? data.data : state))
          };
        }
      );
      queryClient.setQueryData(productionUnitsQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, data, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    productionUnit: data?.data,
    isError,
    error,
    loading: isLoading,
    isSuccess,
    updateProductionUnit: mutateAsync
  };
};
