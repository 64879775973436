import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteAssetProperty = () => {
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (assetId: string) => AcceleratorApi.AssetProperties.delete(assetId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteAssetProperty: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
