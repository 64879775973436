import { useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetSubType, AssetSubTypeListResponse } from "../../../types";
import { assetSubTypesListQueryKey } from "../query-cache";

export const useCreateAssetSubType = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (assetSubType: Partial<AssetSubType>) => {
      return AcceleratorApi.AssetSubTypes.createAssetSubType(assetSubType);
    },
    onSuccess: () => {
      // Invalidate asset types query data.
      queryClient.invalidateQueries<AssetSubTypeListResponse>(assetSubTypesListQueryKey(), {
        exact: true
      });
    }
  });

  return {
    createAssetSubType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
