import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { TagWeights, HTTPError, TagWeightsResponse } from "../../../types";
import { tagWeightsByIdQueryKey } from "../query-cache";
import { AcceleratorApi } from "../../..";

export const useCreateTagWeights = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<TagWeightsResponse, HTTPError, Partial<TagWeights>> = {
    mutationFn: (tagWeights) => AcceleratorApi.TagWeights.createTagWeights(tagWeights),
    onSuccess: (res) => {
      queryClient.setQueryData(tagWeightsByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createTagWeights: mutateAsync,
    tagWeights: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
