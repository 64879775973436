import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { CommonDataApi, HTTPError, PermissionListResponse } from "../../..";
import { permissionsList, permissionsOfApplication, permissionsOfLocation } from "./query-cache";

/**
 * Delete a permission
 */
export const useDeletePermission = (
  applicationId: string,
  locationId: string,
  permissionId: string
) => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<void, HTTPError> = {
    mutationFn: () => CommonDataApi.Permissions.delete(permissionId),
    onSuccess: () => {
      queryClient.setQueryData<PermissionListResponse>(
        permissionsOfLocation(locationId),
        (prevState) => {
          if (!prevState) return undefined;

          return {
            ...prevState,
            data: prevState.data.filter(
              (permission) => permission.permissionTypeId !== permissionId
            )
          };
        }
      );

      queryClient.setQueryData<PermissionListResponse>(permissionsList(), (prevState) => {
        if (!prevState) return undefined;

        return {
          ...prevState,
          data: prevState.data.filter((permission) => permission.permissionTypeId !== permissionId)
        };
      });

      queryClient.setQueryData<PermissionListResponse>(
        permissionsOfApplication(applicationId),
        (prevState) => {
          if (!prevState) return undefined;

          return {
            ...prevState,
            data: prevState.data.filter(
              (permission) => permission.permissionTypeId !== permissionId
            )
          };
        }
      );
    }
  };
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    permissionsData: data,
    deletePermission: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
