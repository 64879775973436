import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetNoteResponse, AssetNote } from "../../../types";

export const useCreateAssetNote = () => {
  const queryOptions: UseMutationOptions<AssetNoteResponse, HTTPError, Partial<AssetNote>> = {
    mutationFn: (assetNote) => AcceleratorApi.AssetNotes.create(assetNote)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createAssetNote: mutateAsync,
    assetNote: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
