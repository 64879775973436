import { FC } from "react";
import { Box, CircularProgress } from "@mui/material";

export const CellLoadingRenderer: FC = () => {
  return (
    <Box sx={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
      <CircularProgress sx={{ alignSelf: "center" }} size={14} />
    </Box>
  );
};
