import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetAnnotationImpactListResponse } from "../../../types";
import { assetAnnotationImpactListQueryKey } from "../query-cache";

export const useGetAssetAnnotationImpact = () => {
  const queryKey = assetAnnotationImpactListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetAnnotationImpactListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetNotes.getAssetAnnotationImpact()
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetAnnotationImpact: data?.data,
    error,
    refetch
  };
};
