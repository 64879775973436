import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AlarmModeDocumentResponse, HTTPError } from "../../../types";
import { alarmModeByIdQueryKey } from "../query-cache";

export const useGetAlarmModeById = (AlarmModeId: string) => {
  const queryKey = alarmModeByIdQueryKey(AlarmModeId);

  const queryOptions: UseQueryOptions<AlarmModeDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AlarmModes.getById(AlarmModeId),
    enabled: Boolean(AlarmModeId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    alarmMode: data?.data,
    error
  };
};
