import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { TagNotificationListResponse, HTTPError, TagNotificationResponse } from "../../../types";

import { AcceleratorApi } from "../../..";
import { tagNotificationsListQueryKey } from "../query-cache";

interface Variables {
  resolved: boolean;
  tagNotificationIds: string[];
}

export const useUpdateTagNotifications = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<TagNotificationResponse[], HTTPError, Variables> = {
    mutationFn: ({ tagNotificationIds, resolved }: Variables) => {
      return Promise.all(
        tagNotificationIds.map((id) => AcceleratorApi.TagNotifications.update(id, resolved))
      );
    },
    onSuccess: (data: TagNotificationResponse[]) => {
      const response = data.map((item) => item.data);
      queryClient.setQueryData<TagNotificationListResponse | undefined>(
        tagNotificationsListQueryKey(),
        (currentState: TagNotificationListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => {
              return response.find((data) => data.id === state.id) || state;
            })
          };
        }
      );
    }
  };

  const { mutateAsync, data, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    tagNotifications: data,
    isError,
    error,
    loading: isLoading,
    isSuccess,
    updateTagNotifications: mutateAsync
  };
};
