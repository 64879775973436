import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { templateByIdQueryKey } from "../query-cache";

export const useGetAssetTemplateById = (templateId?: string) => {
  return useQuery({
    queryKey: templateByIdQueryKey(templateId),
    queryFn: () => AcceleratorApi.Templates.getById(templateId),
    enabled: Boolean(templateId)
  });
};
