import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { TagResponse, HTTPError } from "../../../types";
import { tagsByIdQueryKey } from "../query-cache";

export const useGetTagById = (tagId: string) => {
  const queryKey = tagsByIdQueryKey(tagId);

  const queryOptions: UseQueryOptions<TagResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.Tags.getById(tagId),
    enabled: Boolean(tagId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery<TagResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tag: data?.data,
    error
  };
};
