import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AcceleratorCsvFileUploadConfigsKeys, HTTPError } from "../../../types";
import { csvSampleFileByIdQueryKey } from "../query-cache";

export const useGetSampleCsvFileDownload = (pageConfigKey: AcceleratorCsvFileUploadConfigsKeys) => {
  const queryKey = csvSampleFileByIdQueryKey(pageConfigKey);

  const queryOptions: UseQueryOptions<string, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.CsvUpload.getSampleCsv(pageConfigKey),
    enabled: Boolean(pageConfigKey)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    sampleCsv: data,
    error
  };
};
