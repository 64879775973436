import { useCallback, useState } from "react";
import { AcceleratorApi, ApiListResponse, HTTPError, SearchFilter, SortModelItem } from "../../..";

type SearchParams<T> = {
  searchTerm: string;
  filters?: SearchFilter<T>;
  from: number;
  size: number;
  sortModel?: SortModelItem[];
};

type SearchHookResponse<T> = {
  loading: boolean;
  error: HTTPError | undefined;
  errorMessage: string;
  search: (searchParams: SearchParams<T>) => Promise<ApiListResponse<T>>;
};

type SearchState = {
  loading: boolean;
  error: HTTPError | undefined;
  errorMessage: string | undefined;
};

export const useSearchTagWeights = <T>(): SearchHookResponse<T> => {
  const [searchState, setSearchState] = useState<SearchState>({
    loading: false,
    error: undefined,
    errorMessage: undefined
  });

  const updateInternalState = (updates: SearchState) => {
    setSearchState((s) => ({ ...s, ...updates }));
  };

  const search = useCallback(async (params: SearchParams<T>) => {
    try {
      updateInternalState({ loading: true, error: undefined, errorMessage: undefined });

      const result = await AcceleratorApi.TagWeights.searchTagWeights(
        params.searchTerm,
        params.filters,
        params.sortModel,
        {
          from: params.from,
          size: params.size
        }
      );

      updateInternalState({ loading: false, error: undefined, errorMessage: undefined });

      return result as ApiListResponse<T>;
    } catch (err) {
      const error: HTTPError = err;
      let errorString = "";

      try {
        const responseBody = await error.response.json();
        errorString = responseBody?.error;
      } catch {
        const text = await error.response?.text();

        if (text) {
          errorString = `${errorString}: ${text}`;
        }
      }
      updateInternalState({ loading: false, error: err, errorMessage: errorString });
    }
  }, []);

  return { ...searchState, search };
};
