import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, TagListResponse } from "../../../types";
import { tagsByIdQueryKey } from "../query-cache";

export const useSearchTagById = (tagId: string) => {
  const queryKey = tagsByIdQueryKey(tagId);

  const queryOptions: UseQueryOptions<TagListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.Tags.searchById(tagId),
    enabled: Boolean(tagId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery<TagListResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tag: data?.data ? data.data[0] : null,
    error
  };
};
