import { FC, ReactNode } from "react";
import { QueryClient, QueryClientConfig, QueryClientProvider } from "react-query";

/**
 * Get the existing query client attached to the window or create a new instance.
 *
 * @return {QueryClient} React query QueryClient
 */
const getQueryClient = () => {
  const config: QueryClientConfig = {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        staleTime: 5 * 60 * 1000
      }
    }
  };

  type WindowWithQueryClient = typeof window & { queryClient?: QueryClient };

  const typedWindow: WindowWithQueryClient = window;

  if (!typedWindow.queryClient) {
    typedWindow.queryClient = new QueryClient(config);
  }

  return typedWindow.queryClient;
};

export type Props = {
  children: ReactNode | ReactNode[];
};

/**
 * A common query client provider for all applications. This provides common configuration and reduces duplicate api calls for data.
 *
 * @param {Props} props
 * @param {ReactNode | ReactNode[]} props.children React component(s) to provide client to
 * @return {QueryClientProvider}
 */
export const ApiProvider: FC<Props> = ({ children }) => {
  return <QueryClientProvider client={getQueryClient()}>{children}</QueryClientProvider>;
};
