import * as React from "react";
import { Controller, Control, FieldValues, Path } from "react-hook-form";
import { useSearchTungstenTags } from "@packages/service-api";
import {
  Autocomplete as MUIAutocomplete,
  Box,
  CircularProgress,
  TextField,
  Typography
} from "@mui/material";
import { CopyButton } from "../CopyButton/CopyButton";
import { RenderLabelValue } from "../form";
import { useDebounce } from "../../hooks";

interface AutoCompleteProps<T> {
  onChange?: (data: string) => void;
  label: string;
  systemSearchTerm: string;
  disabled?: boolean;
  name: Path<T>;
  control?: Control<T>;
}

export function SearchTungstenTags<TFieldValues extends FieldValues>(
  props: AutoCompleteProps<TFieldValues>
): JSX.Element {
  const { control, name, label, disabled, systemSearchTerm: oldSystemSearchTerm } = props;
  const [searchTerm, updateSearchTerm] = React.useState<string>("");
  const [systemSearchTerm, updateSystemName] = React.useState<string>("");

  const debouncedSearchValue = useDebounce(searchTerm, 200);
  const { tungstenTags: options, loading } = useSearchTungstenTags(
    debouncedSearchValue,
    systemSearchTerm
  );

  React.useEffect(() => {
    if (oldSystemSearchTerm !== systemSearchTerm) {
      updateSystemName(oldSystemSearchTerm);
    }
  }, [oldSystemSearchTerm]);

  const renderFullTag = (text) =>
    text && (
      <Box py={1} pr={1} mb={2}>
        <CopyButton value={text}>
          <Typography variant="caption" color="GrayText">
            <b>Full Tag:</b>{" "}
          </Typography>
          <Typography variant="caption" color="GrayText">
            {text}
          </Typography>
        </CopyButton>
      </Box>
    );

  return (
    <Box>
      {disabled && (
        <Controller
          name={name}
          control={control}
          render={({ field: { value } }) => (
            <Box>
              <RenderLabelValue label={label} value={value} />
              {renderFullTag(value)}
            </Box>
          )}
        />
      )}

      {!disabled && (
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange }, fieldState: { invalid, error } }) => (
            <MUIAutocomplete
              clearOnBlur={false}
              value={value}
              options={options}
              onChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) => {
                onChange(value);
              }}
              onInputChange={(event: React.ChangeEvent<HTMLInputElement>, value: string) =>
                updateSearchTerm(value)
              }
              filterOptions={(options) => options}
              loading={loading}
              renderInput={(params) => (
                <Box pt={1}>
                  <TextField
                    {...params}
                    placeholder="Search tag"
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? <CircularProgress size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      )
                    }}
                    label={label}
                    value={value}
                    error={invalid}
                    helperText={error ? error.message : null}
                    required={true}
                  />

                  {renderFullTag(value)}
                </Box>
              )}
            />
          )}
        />
      )}
    </Box>
  );
}

SearchTungstenTags.defaultProps = {
  multiple: true
};
