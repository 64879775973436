import { FC } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { green } from "@mui/material/colors";

import { ApplicationIcon } from "@packages/theme-mui-v5";

export type MultiCellEditActionLinkProps = {
  selectedCellsCount: number;
  selectedGroupsCount: number;
  onActionLinkClicked: () => void;
};

export const MultiCellEditActionLink: FC<MultiCellEditActionLinkProps> = ({
  selectedCellsCount,
  selectedGroupsCount,
  onActionLinkClicked
}) => {
  if (selectedGroupsCount > 1) {
    return (
      <Tooltip
        title={
          <Typography variant="body1" sx={{ marginX: 1, cursor: "not-allowed", fontSize: "16px" }}>
            Selection includes Multiple column groups. Columns of the same group are highlighted
            using the same color. Only one group of columns can be selected for bulk edting at a
            time
          </Typography>
        }
      >
        <Box
          sx={(theme) => ({
            color: theme.palette.error.main,
            display: "flex",
            alignItems: "center"
          })}
        >
          <ApplicationIcon name="warning" />
          <Typography variant="body1" sx={{ marginX: 1, fontSize: "16px", fontWeight: 500 }}>
            Invalid selection
          </Typography>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Typography
      variant="body1"
      sx={{
        m: 0,
        p: 0,
        fontWeight: 500,
        textDecoration: "underline",
        "&:hover": {
          bgcolor: green,
          color: green,
          cursor: "pointer"
        }
      }}
      onClick={onActionLinkClicked}
    >
      {selectedCellsCount} item{selectedCellsCount > 1 ? "s" : ""} selected
    </Typography>
  );
};
