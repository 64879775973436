import { QueryKeyFunction } from "../../../types";

export const groupMembersByGroupId: QueryKeyFunction = (groupId: string) => [
  "common-data",
  "groups",
  groupId,
  "members",
  "list"
];

export const permissionsOfGroupByGroupId: QueryKeyFunction = (groupId: string) => [
  "common-data",
  "groups",
  groupId,
  "permissions",
  "list"
];
export const groupsByLocationId: QueryKeyFunction = (locationId: string) => [
  "common-data",
  "groups",
  locationId,
  "list"
];

export const groupsOfPersonId = (userId: string) => ["common-data", "users", userId, "groups"];

export const groupById = (groupId: string) => ["common-data", "groups", groupId, "single"];

export const groupByLocationId = (locationId: string) => [
  "common-data",
  "groups",
  "by-location",
  locationId
];
