import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { Tag, HTTPError, TagResponse } from "../../../types";

export const useCreateTag = () => {
  const queryOptions: UseMutationOptions<TagResponse, HTTPError, Partial<Tag>> = {
    mutationFn: (tag) => AcceleratorApi.Tags.create(tag)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createTag: mutateAsync,
    tag: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
