import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import {
  AssetType,
  AssetTypeDocumentResponse,
  AssetTypeListResponse,
  HTTPError
} from "../../../types";
import { assetTypesListQueryKey, assetTypeByIdQueryKey } from "../query-cache";

import { useGetAssetTypeById } from "./useGetAssetTypeById";

export const useUpdateAssetType = (assetTypeId: string) => {
  const queryClient = useQueryClient();
  const { assetType } = useGetAssetTypeById(assetTypeId);

  const queryOptions: UseMutationOptions<
    AssetTypeDocumentResponse,
    HTTPError,
    Partial<AssetType>
  > = {
    mutationFn: (updates) =>
      AcceleratorApi.AssetTypes.patchAssetType(assetTypeId, assetType, updates),
    onSuccess: (assetType: AssetTypeDocumentResponse) => {
      // Invalidate equipment types query data.
      queryClient.invalidateQueries<AssetTypeListResponse>(assetTypesListQueryKey(), {
        exact: true
      });

      // Invalidate asset type by ID query data.
      queryClient.invalidateQueries<AssetTypeDocumentResponse>(
        assetTypeByIdQueryKey(assetType.data.id)
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(queryOptions);

  return {
    updateAssetType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    assetType: data?.data,
    error
  };
};
