import { SnackbarProvider as Provider } from "notistack";
import { useMediaQuery } from "../../hooks";

import "./snackbar-styles.css";

interface SnackbarProvider {
  children: React.ReactNode;
}

const SnackbarProvider = (props: SnackbarProvider) => {
  const { smUp } = useMediaQuery();
  return (
    <Provider
      classes={{
        containerRoot: "snackbar-container-root"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: smUp ? "center" : "center"
      }}
    >
      {props.children}
    </Provider>
  );
};

export default SnackbarProvider;
