import * as React from "react";
import { Autocomplete, TextField, Checkbox, Box, FormGroup, FormControlLabel } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useDebounce } from "@packages/theme-mui-v5";
import { People, useGetSiteById, useSearchPeople } from "@packages/service-api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useLocalState from "../hooks/useLocalState";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AutoCompleteProps {
  onChange?: (data: People[]) => void;
  multiple?: boolean;
}

const AutoComplete = (props: AutoCompleteProps) => {
  const { onChange, multiple } = props;
  const [searchTerm, updateSearchTerm] = React.useState<string>("");
  const [allSites, updateAllSites] = React.useState<boolean>(false);

  const { siteId } = useLocalState();
  const { site, loading: siteLoading } = useGetSiteById(siteId);

  const debouncedSearchValue = useDebounce(searchTerm, 200);

  const { users, loading } = useSearchPeople(
    debouncedSearchValue,
    !allSites ? site?.siteId : undefined
  );

  const onAutoCompleteChange = (event: React.SyntheticEvent<Element, Event>, values: People[]) => {
    onChange && onChange(values);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        {site && (
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={!allSites} onChange={() => updateAllSites(!allSites)} />}
              label={`Limit to ${site?.siteName}`}
            />
          </FormGroup>
        )}
      </Box>
      <Autocomplete
        clearOnBlur={false}
        multiple={multiple}
        limitTags={3}
        disabled={siteLoading}
        id="users"
        options={users}
        onChange={onAutoCompleteChange}
        onInputChange={(event: React.SyntheticEvent<Element, Event>, value: string) =>
          updateSearchTerm(value)
        }
        filterOptions={(options) => options}
        getOptionLabel={(option: People) => `${option.firstName} ${option.lastName}`}
        loading={loading}
        isOptionEqualToValue={(option, value) => option.personId === value.personId}
        ListboxProps={{
          style: {
            maxHeight: "60vh"
          }
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.personId}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            <span>{`${option.firstName} ${option.lastName} (${option.userName})`}</span>
          </li>
        )}
        renderInput={(params) => (
          <Box pt={1}>
            <TextField
              {...params}
              placeholder="Search users"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          </Box>
        )}
      />
    </Box>
  );
};

AutoComplete.defaultProps = {
  multiple: true
};

export default AutoComplete;
