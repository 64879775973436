import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { DataSource, DataSourceResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { dataSourcesByIdQueryKey } from "../query-cache";

type Variables = {
  previousDataSource: DataSource;
  dataSource: Partial<DataSource>;
};

/**
 * Hook to update DataSource
 */
export const useUpdateDataSource = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<DataSourceResponse, HTTPError, Variables> = {
    mutationFn: ({ previousDataSource, dataSource }: Variables) =>
      AcceleratorApi.DataSource.patchDataSource(previousDataSource, dataSource),
    onSuccess: (res) => {
      queryClient.setQueryData(dataSourcesByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    dataSource: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateDataSource: mutateAsync
  };
};
