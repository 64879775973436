import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError, PermissionListResponse } from "../../..";
import { permissionsOfGroupByGroupId } from "./query-cache";

/**
 * Hook to get permissions of a group
 */
export const useGetGroupPermissions = (groupId: string) => {
  const queryOptions: UseQueryOptions<PermissionListResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Groups.getGroupPermissions(groupId),
    queryKey: permissionsOfGroupByGroupId(groupId),
    enabled: Boolean(groupId)
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    permissions: data ? data.data : [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
