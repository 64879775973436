import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import CreateGroup from "./drawers/groups/CreateGroup";
import ManageGroupMembers from "./drawers/groups/ManageGroupMembers";
import DeleteGroup from "./drawers/groups/DeleteGroup";
import EditGroup from "./drawers/groups/EditGroup";
import LandingPage from "./pages/LandingPage";
import EditGroupPermissions from "./drawers/groups/EditGroupPermissions";
import CreatePermissions from "./drawers/permissions/AddPermissions";
import EditPermission from "./drawers/permissions/EditPermission";
import DeletePermission from "./drawers/permissions/DeletePermission";
import AddUsersToGroups from "./drawers/users/AddUsersToGroups";
import ManageUser from "./drawers/users/ManageUser";

type Props = {
  basePath?: string;
};

const Router: React.FC<Props> = () => {
  return (
    <Routes>
      <Route path="auth/groups" element={<LandingPage activeTab="groups" />}>
        <Route path="create" element={<CreateGroup />} />
        <Route path=":id/manage-users" element={<ManageGroupMembers />} />
        <Route path=":id/edit" element={<EditGroup />} />
        <Route path=":id/delete" element={<DeleteGroup />} />
        <Route path=":id/edit-permissions" element={<EditGroupPermissions />} />
      </Route>
      <Route path="auth/users" element={<LandingPage activeTab="users" />}>
        <Route path="add" element={<AddUsersToGroups />} />
        <Route path=":id/manage-user" element={<ManageUser />} />
      </Route>
      <Route path="auth/permissions" element={<LandingPage activeTab="permissions" />}>
        <Route path="create" element={<CreatePermissions />} />
        <Route path=":id/edit" element={<EditPermission />} />
        <Route path=":id/delete" element={<DeletePermission />} />
      </Route>
      <Route path="*" element={<Navigate to="/admin/auth/groups" replace />} />
    </Routes>
  );
};

export default Router;
