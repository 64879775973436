import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetNoteListResponse } from "../../../types";
import { assetNoteSearchByIdQueryKey } from "../query-cache";

export const useSearchAssetNoteById = (assetNoteId: string) => {
  const queryKey = assetNoteSearchByIdQueryKey(assetNoteId);

  const queryOptions: UseQueryOptions<AssetNoteListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetNotes.searchAssetNoteById(assetNoteId),
    enabled: Boolean(assetNoteId)
  };

  const { isLoading, refetch, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetNote: data?.data?.length ? data?.data[0] : null,
    refetch,
    error
  };
};
