import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, NameplateLabelsListResponse } from "../../../types";
import { nameplateLabelsListQueryKey } from "../query-cache";

export const useDeleteNameplateLabel = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (nameplateLabelId: string) =>
      AcceleratorApi.NameplateLabels.delete(nameplateLabelId),
    onSuccess: (_, id) => {
      queryClient.setQueryData<NameplateLabelsListResponse | undefined>(
        nameplateLabelsListQueryKey(),
        (currentState: NameplateLabelsListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.filter((item) => item.id !== id)
          };
        }
      );
    }
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteNameplateLabel: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
