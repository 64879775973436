import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetTypeListResponse } from "../../../types";
import { assetTypesListQueryKey } from "../query-cache";

export const useGetAssetTypes = ({ enabled }: { enabled?: boolean } = {}) => {
  const queryKey = assetTypesListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<AssetTypeListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.AssetTypes.getAssetTypes(),
    enabled
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetTypes: data?.data,
    error,
    refetch
  };
};
