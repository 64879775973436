import React, { useMemo } from "react";
import { ApplicationAccessPermissions } from "../ApplicationAccessPermissionsProvider";
import { Box, Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { TreeNode } from "../drawers/groups/EditGroupPermissions";

export type DefinedPermissionsFormProps = {
  value: string[];
  onChange: (value: string[]) => void;
  definitions: ApplicationAccessPermissions;

  /** A full list of permissions in `TreeNode` structure.
   * Any permissions not found in `definitions` will be displayed under "Other Groups".
   */
  tree: TreeNode[];
};

function getNonCrudPermissions(definitions: ApplicationAccessPermissions, tree: TreeNode[]) {
  const other: Array<{ label: string; permissionId: string }> = [];

  for (const node of tree) {
    if (node.children) {
      for (const child of node.children) {
        if (
          !definitions.cruds?.some((definition) => {
            return (
              definition.crud.create === child.nodeId ||
              definition.crud.read === child.nodeId ||
              definition.crud.update === child.nodeId ||
              definition.crud.delete === child.nodeId
            );
          })
        ) {
          other.push({ label: child.label, permissionId: child.nodeId });
        }
      }
    }
  }

  return other;
}

export function DefinedPermissionsForm({
  value,
  onChange,
  definitions,
  tree
}: DefinedPermissionsFormProps) {
  const other = useMemo(() => getNonCrudPermissions(definitions, tree), [definitions, tree]);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Table size="small" padding="none">
        <TableHead>
          <TableRow>
            <TableCell>Group</TableCell>
            <TableCell>Create</TableCell>
            <TableCell>Read</TableCell>
            <TableCell>Update</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {definitions.cruds?.map((definition) => {
            return (
              <TableRow key={definition.label}>
                <TableCell>{definition.label}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={value.includes(definition.crud.create)}
                    onChange={(event, checked) => {
                      if (checked) {
                        const newValue = [...value, definition.crud.create];
                        onChange(newValue);
                      } else {
                        const newValue = value.filter((v) => v !== definition.crud.create);
                        onChange(newValue);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={value.includes(definition.crud.read)}
                    onChange={(event, checked) => {
                      if (checked) {
                        const newValue = [...value, definition.crud.read];
                        onChange(newValue);
                      } else {
                        const newValue = value.filter((v) => v !== definition.crud.read);
                        onChange(newValue);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={value.includes(definition.crud.update)}
                    onChange={(event, checked) => {
                      if (checked) {
                        const newValue = [...value, definition.crud.update];
                        onChange(newValue);
                      } else {
                        const newValue = value.filter((v) => v !== definition.crud.update);
                        onChange(newValue);
                      }
                    }}
                  />
                </TableCell>
                <TableCell>
                  <Checkbox
                    checked={value.includes(definition.crud.delete)}
                    onChange={(event, checked) => {
                      if (checked) {
                        const newValue = [...value, definition.crud.delete];
                        onChange(newValue);
                      } else {
                        const newValue = value.filter((v) => v !== definition.crud.delete);
                        onChange(newValue);
                      }
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {other?.length > 0 && (
        <Table size="small" padding="none">
          <TableHead>
            <TableRow>
              <TableCell>Group</TableCell>
              <TableCell>Access</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {other?.map(({ label, permissionId }) => {
              return (
                <TableRow key={permissionId}>
                  <TableCell>{label}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={value.includes(permissionId)}
                      onChange={(event, checked) => {
                        if (checked) {
                          const newValue = [...value, permissionId];
                          onChange(newValue);
                        } else {
                          const newValue = value.filter((v) => v !== permissionId);
                          onChange(newValue);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}
