import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  CommonDataApi,
  HTTPError,
  PermissionDocumentResponse,
  PermissionListResponse
} from "../../..";
import { permissionsOfGroupByGroupId } from "./query-cache";

/**
 * Hook to remove one or more permissions from a group
 */
export const useRemoveGroupPermissions = (groupId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PermissionDocumentResponse, HTTPError, string[]> = {
    mutationFn: (permissions: string[]) =>
      CommonDataApi.Groups.removeGroupPermissions(groupId, permissions),
    onSuccess: (_response, permissions) => {
      queryClient.setQueryData<PermissionListResponse | undefined>(
        permissionsOfGroupByGroupId(groupId),
        (preState) => {
          if (!preState) return;
          return {
            ...preState,
            data: preState.data.filter((p) => !permissions.includes(p.permissionTypeId))
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(queryOptions);

  return {
    removeGroupPermissions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
