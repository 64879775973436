import * as React from "react";
import { Alert, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";

import {
  useAddGroupPermissions,
  useGetGroupPermissions,
  useGetPermissionsByApplicationId,
  useGroupById,
  useRemoveGroupPermissions
} from "@packages/service-api";

import { DefinedPermissionsForm } from "../../components/DefinedPermissionsForm";
import PermissionsTreeView from "../../components/Permissions";
import { formatPermissionsData, getAddedAndRemovedPermissions } from "../../utils";
import useLocalState from "../../hooks/useLocalState";
import { useApplicationAccessPermissionsContext } from "../../ApplicationAccessPermissionsProvider";

export interface TreeNode {
  label?: string;
  parent?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  selected?: boolean;
  nodeId?: string;
  parentId?: string;
  children?: TreeNode[];
}

export type PermissionsProps = {
  permissionsTree?: TreeNode[];
};

export type PermissionsType = {
  getPermissions: () => TreeNode[];
};

const EditPermissions = () => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { applicationId } = useLocalState();

  const { group } = useGroupById(id);
  const ref = React.useRef<PermissionsType>(null);
  const {
    permissions,
    loading,
    isError: permissionsError
  } = useGetPermissionsByApplicationId(applicationId);

  const {
    permissions: groupPermissions,
    loading: permissionsLoading,
    isError
  } = useGetGroupPermissions(id);

  const permissionsTree = React.useMemo(() => {
    return formatPermissionsData(permissions, "module", groupPermissions);
  }, [permissions, groupPermissions]);

  const {
    addGroupPermissions,
    loading: addPermissionsLoading,
    isError: addPermissionsHasError
  } = useAddGroupPermissions(id);
  const {
    removeGroupPermissions,
    loading: removePermissionsLoading,
    isError: removePermissionsHasError
  } = useRemoveGroupPermissions(id);

  const navigate = useNavigate();

  const onClose = () => {
    navigate("/admin/auth/groups");
  };

  const handleSave = async () => {
    let addedPermissions: string[] = [];
    let removedPermissions: string[] = [];

    if (definitions) {
      const before = groupPermissions.map((permission) => permission.permissionTypeId);
      addedPermissions = checked.filter((permission) => !before.includes(permission));
      removedPermissions = before.filter((permission) => !checked.includes(permission));
    } else {
      const newPermissionTree = ref.current?.getPermissions();
      [addedPermissions, removedPermissions] = getAddedAndRemovedPermissions(
        newPermissionTree,
        groupPermissions
      );
    }

    try {
      if (addedPermissions.length) {
        await addGroupPermissions(addedPermissions);
      }
      if (removedPermissions.length) {
        await removeGroupPermissions(removedPermissions);
      }
      enqueueSnackbar("Permissions updated successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const definitions = useApplicationAccessPermissionsContext();

  const [checked, setChecked] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (groupPermissions) {
      setChecked(groupPermissions.map((permission) => permission.permissionTypeId));
    }
  }, [groupPermissions]);

  return (
    <Drawer
      contentTitle="Permissions"
      title={`Edit Permissions | ${group?.groupName || ""}`}
      onClose={onClose}
      hideContent={loading || permissionsLoading}
      loading={loading || addPermissionsLoading || removePermissionsLoading}
      actions={[
        {
          text: "Save",
          action: handleSave,
          disabled: addPermissionsLoading || removePermissionsLoading
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      {(isError || addPermissionsHasError || removePermissionsHasError || permissionsError) && (
        <Box mb={2}>
          <Alert severity="error">
            Unfortunately, We are unable to save your changes. Please try again.
          </Alert>
        </Box>
      )}

      {!loading && !permissionsLoading && !!permissions.length && (
        <>
          {definitions !== false ? (
            <DefinedPermissionsForm
              value={checked}
              onChange={setChecked}
              definitions={definitions}
              tree={permissionsTree}
            />
          ) : (
            <PermissionsTreeView permissionsTree={permissionsTree} ref={ref} />
          )}
        </>
      )}
    </Drawer>
  );
};

export default EditPermissions;
