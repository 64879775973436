import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { AcceleratorApi } from "../../../apis";
import {
  AcceleratorCsvFileUploadConfigsKeys,
  AcceleratorCsvListResponse,
  HTTPError
} from "../../../types";

type OnSuccess = () => void;

export const useAcceleratorCsvFileUpload = (
  pageConfigKey: AcceleratorCsvFileUploadConfigsKeys,
  onSuccess: OnSuccess
) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<AcceleratorCsvListResponse, HTTPError, File> = {
    mutationFn: (file) => AcceleratorApi.CsvUpload.csvUpload(file, pageConfigKey),
    onSuccess: () => {
      queryClient.invalidateQueries(["accelerator", pageConfigKey, "list"]);
      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(queryOptions);

  return {
    uploadCsvFile: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    csvData: data?.data,
    error
  };
};
