import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Asset, AssetResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { assetByIdQueryKey } from "../query-cache";

type Variables = {
  previousAsset: Asset;
  asset: Partial<Asset>;
};

/**
 * Hook to update an Asset
 */
export const useUpdateAsset = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<AssetResponse, HTTPError, Variables> = {
    mutationFn: ({ previousAsset, asset }: Variables) =>
      AcceleratorApi.Assets.updateAsset(previousAsset, asset),
    onSuccess: (res) => {
      queryClient.setQueryData(assetByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    asset: data?.data,
    isSuccess,
    error: error,
    loading: isLoading,
    updateAsset: mutateAsync
  };
};
