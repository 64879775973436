import { UseMutationOptions, useMutation } from "react-query";
import { AssetTemplate, AssetTemplateDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousTemplate: AssetTemplate;
  template: Partial<AssetTemplate>;
};

/**
 * Hook to update an Template
 */
export const useUpdateAssetTemplate = () => {
  const queryOptions: UseMutationOptions<AssetTemplateDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ previousTemplate, template }: Variables) =>
      AcceleratorApi.Templates.update(previousTemplate, template)
  };

  const { mutateAsync, isLoading, error, data } = useMutation(queryOptions);

  return {
    template: data?.data,
    error: error,
    loading: isLoading,
    updateTemplate: mutateAsync
  };
};
