import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError, TagMapping, TagMappingListResponse } from "../../../types";

export const useMapTags = () => {
  const queryOptions: UseMutationOptions<TagMappingListResponse, HTTPError, TagMapping[]> = {
    mutationFn: (tagMappings: TagMapping[]) => AcceleratorApi.TagMappings.mapTags(tagMappings)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    mapTags: mutateAsync,
    tagMapping: data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
