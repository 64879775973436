import { useContext } from "react";
import {
  ApplicationAccessContext,
  ApplicationAccessManagementProps
} from "../ApplicationAccessManagement";

const useLocalState = () => {
  const { siteId, applicationId, selectedLocationId, hidePageContainer } =
    useContext<ApplicationAccessManagementProps>(ApplicationAccessContext);
  return {
    siteId,
    hidePageContainer,
    applicationId,
    selectedLocationId
  };
};

export default useLocalState;
