import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, QueryOptions, TagListResponse } from "../../../types";
import { searchTungstenTagsByIdQueryKey } from "../query-cache";

export const useSearchTungstenTagNames = (
  tagName: string,
  system: string,
  query?: string,
  options?: QueryOptions
) => {
  const queryKey = searchTungstenTagsByIdQueryKey(tagName, system, query);

  const queryOptions: UseQueryOptions<TagListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.TagMappings.searchTungstenTags(tagName, system, query, options),
    enabled: !!tagName || !!query
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    tungstenTags: data?.data,
    error
  };
};
