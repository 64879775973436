import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError } from "../../..";
import { GroupListResponse } from "../../..";
import { groupsOfPersonId } from "./query-cache";

/**
 *  Hook to get groups that a user (Person) is a member of
 */
export const useGetGroupsByUserId = (
  applicationId: string,
  locationId: string,
  siteId: string,
  personId: string
) => {
  const queryOptions: UseQueryOptions<GroupListResponse, HTTPError> = {
    queryFn: () => CommonDataApi.Groups.getByUserId(applicationId, siteId, locationId, personId),
    queryKey: groupsOfPersonId(personId),
    select: (response) => {
      if (!response) {
        return undefined;
      }
      return {
        ...response,
        data: response.data.sort((a, b) =>
          a.groupName > b.groupName ? 1 : b.groupName > a.groupName ? -1 : 0
        )
      };
    },
    enabled: Boolean(personId)
  };

  const { data, isLoading, isError, isSuccess } = useQuery<GroupListResponse>(queryOptions);

  return {
    groups: data?.data || [],
    loading: isLoading,
    isError,
    isSuccess
  };
};
