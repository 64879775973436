import * as React from "react";
import { Box, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import { Group, useCreateGroup } from "@packages/service-api";
import GroupForm, { GroupFormRef } from "../../components/GroupForm";
import useLocalState from "../../hooks/useLocalState";

const defaultGroup = {
  groupName: "",
  description: ""
};

const CreateGroup: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { applicationId, selectedLocationId } = useLocalState();
  const { createGroup, loading, isError } = useCreateGroup(applicationId, selectedLocationId);
  const navigate = useNavigate();
  const ref = React.useRef<GroupFormRef>(null);

  const handleSubmit = async (values: Group) => {
    await createGroup(values);
  };

  const onClose = () => {
    navigate("/admin/auth/groups");
  };

  const handleSave = async () => {
    try {
      ref.current && ref.current.onSubmit();

      enqueueSnackbar("Group created successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <Drawer
      contentTitle="Create Group"
      title="Add Group"
      onClose={onClose}
      loading={loading}
      actions={[
        {
          text: "Create",
          action: handleSave,
          disabled: loading
        },
        { text: "Cancel", action: onClose }
      ]}
    >
      {isError && (
        <Box mb={2}>
          <Alert severity="error">
            Unfortunately, We are unable to save your changes. Please try again.
          </Alert>
        </Box>
      )}
      <GroupForm onSubmit={handleSubmit} group={defaultGroup} ref={ref} />
    </Drawer>
  );
};

export default CreateGroup;
