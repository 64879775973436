import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TreeView as MUITreeView } from "@mui/lab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface TreeViewProps {
  children: React.ReactNode;
  expanded: string[];
  onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
}

const TreeView = (props: TreeViewProps) => {
  const { children, expanded, onNodeToggle } = props;

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    onNodeToggle && onNodeToggle(event, nodeIds);
  };

  return (
    <MUITreeView
      expanded={expanded}
      onNodeToggle={handleToggle}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {children}
    </MUITreeView>
  );
};

export default TreeView;
