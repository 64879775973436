import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider, SnackbarProvider } from "@packages/theme-mui-v5";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { useSelectedLocation, useUserProfile } from "@packages/service-api";
import Router from "./Router";

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } }
});

export type Props = {
  applicationId: string;
  basePath: string;
  hidePageContainer?: boolean;
};

export interface ApplicationAccessManagementProps extends Props {
  siteId: string;
  selectedLocationId?: string;
  hidePageContainer?: boolean;
}

export const ApplicationAccessContext = React.createContext<ApplicationAccessManagementProps>(null);

const ApplicationAccessManagement: React.FC<Props> = (props) => {
  const profile = useUserProfile();

  const [siteId, setSiteId] = React.useState<string>();
  const { selectedLocation } = useSelectedLocation(props.applicationId);

  React.useEffect(() => {
    const nextSiteId = profile.preferences.selectedSiteId || profile.siteId;
    setSiteId(nextSiteId);
  }, [profile]);

  return (
    <ApplicationAccessContext.Provider
      value={{ ...props, siteId, selectedLocationId: selectedLocation?.locationId }}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider provider={MUIThemeProvider}>
          <SnackbarProvider>
            <Router basePath={props.basePath} />
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </ApplicationAccessContext.Provider>
  );
};

export default ApplicationAccessManagement;
