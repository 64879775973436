import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetAnnotationActionListResponse } from "../../../types";
import { assetAnnotationActionListQueryKey } from "../query-cache";

export const useGetAssetAnnotationAction = () => {
  const queryKey = assetAnnotationActionListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetAnnotationActionListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetNotes.getAssetAnnotationAction()
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetAnnotationActions: data?.data,
    error,
    refetch
  };
};
