import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { PlantsListResponse } from "../../../types";
import { assetAnnotationSourceListQueryKey } from "../query-cache";

export const useGetAssetAnnotationSource = () => {
  const queryKey = assetAnnotationSourceListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<PlantsListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.AssetNotes.getAssetAnnotationSource()
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetAnnotationSource: data?.data,
    error,
    refetch
  };
};
