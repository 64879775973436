import React from "react";
import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";
import { ICellRendererParams } from "ag-grid-community";
import { styled } from "@mui/material/styles";
import { Tooltip, TooltipProps } from "@mui/material";

const CheckWrapper = styled("div")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  height: "100%",
  color: theme.palette.primary.main
}));

const ClosekWrapper = styled(CheckWrapper)(({ theme }) => ({
  color: theme.palette.error.main
}));

type Props = ICellRendererParams & {
  nullAsFalse?: boolean;
  tooltipProps?: TooltipProps;
};

export const BooleanRenderer: React.FC<Props> = ({ nullAsFalse, tooltipProps, value }) => {
  const icon =
    value === "true" || value === true || value === "Yes" ? (
      <CheckWrapper>
        <ApplicationIcon name={"check" as ApplicationIconName} />
      </CheckWrapper>
    ) : value === "false" || value === false || value === "No" || nullAsFalse ? (
      <ClosekWrapper>
        <ApplicationIcon name={"close" as ApplicationIconName} />
      </ClosekWrapper>
    ) : null;

  if (!tooltipProps) {
    return icon;
  }

  return (
    <Tooltip {...tooltipProps}>
      <span>{icon}</span>
    </Tooltip>
  );
};
