import { FC } from "react";
import { Alert, Box } from "@mui/material";

import { isEmpty } from "@packages/utils";

export type Note = string;

export type MultiCellEditNotesProps = {
  notes?: Note[];
};

export const MultiCellEditNotes: FC<MultiCellEditNotesProps> = ({ notes }) => {
  if (isEmpty(notes)) {
    return null;
  }

  return (
    <Alert sx={{ mb: 2 }} severity="warning">
      <Box sx={{ fontWeight: "bold" }}>Notes:</Box>
      <Box component="ul" sx={{ m: 1, pl: 2 }}>
        {(notes ?? []).map((note, index) => (
          <Box key={index} component="li" sx={{ mb: 0.5 }}>
            {notes}
          </Box>
        ))}
      </Box>
    </Alert>
  );
};
