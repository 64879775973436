import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { PersonaListResponse } from "../../../types";
import { personasListQueryKey } from "../query-cache";

export const useGetPersonas = () => {
  const queryKey = personasListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } = useQuery<PersonaListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.Personas.getPersonas()
  });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    personas: data?.data || [],
    error,
    refetch
  };
};
