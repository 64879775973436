import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetSubTypeListResponse } from "../../../types";
import { assetSubTypesListQueryKey } from "../query-cache";

export const useGetAssetSubTypesByAssetId = ({
  assetTypeId,
  enabled
}: {
  assetTypeId?: string;
  enabled?: boolean;
} = {}) => {
  const queryKey = assetSubTypesListQueryKey(assetTypeId);

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetSubTypeListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetSubTypes.getByAssetId(assetTypeId),
      enabled
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetSubTypes: data?.data,
    error,
    refetch
  };
};
