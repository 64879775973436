import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Tag, TagResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { tagsByIdQueryKey } from "../query-cache";

type Variables = {
  previousTag: Tag;
  tag: Partial<Tag>;
};

/**
 * Hook to update tag
 */
export const useUpdateTag = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<TagResponse, HTTPError, Variables> = {
    mutationFn: ({ previousTag, tag }: Variables) => AcceleratorApi.Tags.update(previousTag, tag),
    onSuccess: (res) => {
      queryClient.setQueryData(tagsByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    tag: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateTag: mutateAsync
  };
};
