import { useMutation, UseMutationOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";

export function useDeleteSteadyStateMutation(
  options: UseMutationOptions<unknown, unknown, { id: string }> = {}
) {
  return useMutation({
    mutationFn: ({ id }) => AcceleratorApi.SteadyState.delete(id),
    ...options
  });
}
