import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetSubTypeDocumentResponse, HTTPError } from "../../../types";
import { assetSubTypeByIdQueryKey } from "../query-cache";

export const useGetAssetSubTypeById = (assetSubTypeId: string) => {
  const queryKey = assetSubTypeByIdQueryKey(assetSubTypeId);

  const queryOptions: UseQueryOptions<AssetSubTypeDocumentResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetSubTypes.getById(assetSubTypeId),
    enabled: Boolean(assetSubTypeId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetSubType: data?.data,
    error
  };
};
