import { FC } from "react";
import { Alert, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Drawer, WithLoader, useSnackbar } from "@packages/theme-mui-v5";
import { useDeleteGroup, useGroupById } from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const DeleteGroup: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { group, loading: isLoading } = useGroupById(id);
  const { applicationId, selectedLocationId } = useLocalState();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { deleteGroup, loading, isError } = useDeleteGroup(applicationId, selectedLocationId);

  const onClose = () => {
    navigate("/admin/auth/groups");
  };

  const handleSave = async () => {
    try {
      await deleteGroup(id);

      enqueueSnackbar("Group deleted successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <Drawer
      title="Delete Group"
      onClose={onClose}
      loading={loading || isLoading}
      actions={[
        { text: "Delete", action: handleSave, disabled: loading || isLoading },
        { text: "Cancel", action: onClose }
      ]}
    >
      {isError && (
        <Box mb={2}>
          <Alert severity="error">
            Unfortunately, We are unable to save your changes. Please try again.
          </Alert>
        </Box>
      )}
      <WithLoader loading={loading || isLoading}>
        {group?.groupName && (
          <div>
            Are you sure you want to delete <b>{`${group?.groupName}`}</b> group?
          </div>
        )}
      </WithLoader>
    </Drawer>
  );
};

export default DeleteGroup;
