import { useMutation } from "react-query";
import { CreateAttachmentRequest } from "../../../types";
import { createAssetNoteAttachment } from "../../../apis/accelerator/asset-notes";

export const useCreateAssetNoteAttachment = (assetNoteId: string) => {
  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation({
    mutationFn: (request: CreateAttachmentRequest) => {
      return createAssetNoteAttachment({ assetNoteId, request });
    }
  });

  return {
    createAssetNoteAttachment: mutateAsync,
    attach: data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
