import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteAsset = () => {
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (assetId: string) => AcceleratorApi.Assets.deleteAsset(assetId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteAsset: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
