import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { DataSource, HTTPError, DataSourceResponse } from "../../../types";

export const useCreateDataSource = () => {
  const queryOptions: UseMutationOptions<DataSourceResponse, HTTPError, Partial<DataSource>> = {
    mutationFn: (dataSource) => AcceleratorApi.DataSource.createDataSource(dataSource)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createDataSource: mutateAsync,
    dataSource: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
