import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi, GroupListResponse, HTTPError } from "../../..";
import { groupsByLocationId } from "./query-cache";

/**
 * Hook to delete a group
 */
export const useDeleteGroup = (applicationId: string, locationId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (groupId) => CommonDataApi.Groups.delete(groupId),
    onSuccess: (_response, groupId) => {
      queryClient.setQueryData<GroupListResponse | undefined>(
        groupsByLocationId(applicationId, locationId),
        (prevState) => {
          if (!prevState) {
            return;
          }
          return {
            ...prevState,
            data: prevState.data.filter((group) => group.groupId !== groupId),
            total: prevState.total - 1
          };
        }
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    deleteGroup: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
