import { useEffect, useRef, useState } from "react";
import { IHeaderParams } from "ag-grid-community";
import { Tooltip, IconButton, Box } from "@mui/material";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { ApplicationIcon, ApplicationIconName } from "@packages/theme-mui-v5";

export interface ICustomHeaderParams extends IHeaderParams {
  menuIcon: string;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px"
  }
}));

type SortOrder = "asc" | "desc" | null;

export const HeaderRenderer = (props: ICustomHeaderParams) => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(
    props.column.isSortAscending() ? "asc" : props.column.isSortDescending() ? "desc" : null
  );
  const refButton = useRef(null);

  const onMenuClicked = () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    props.showColumnMenu(refButton.current!);
  };

  const onSortRequested = (event) => {
    if (props.enableSorting) {
      const order = sortOrder === "asc" ? "desc" : sortOrder === "desc" ? null : "asc";
      props.setSort(order, event.shiftKey);
      setSortOrder(order);
    }
  };

  const onSortChanged = () => {
    setSortOrder(
      props.column.isSortAscending() ? "asc" : props.column.isSortDescending() ? "desc" : null
    );
  };

  useEffect(() => {
    onSortChanged();

    props.column.addEventListener("sortChanged", onSortChanged);

    return () => {
      props.column.removeEventListener("sortChanged", onSortChanged);
    };
  }, []);

  let menu = null;

  if (props.enableMenu) {
    const filter = props.api.getFilterModel();
    const filterNumber = filter[props.menuIcon]?.values?.length;

    menu = (
      <Box
        ref={refButton}
        onClick={() => onMenuClicked()}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          mr: 1
        }}
      >
        <Tooltip title="Filter">
          <IconButton color="primary" size="small">
            {filterNumber ? (
              <Box sx={{ position: "relative" }}>
                <StyledBadge max={999} badgeContent={filterNumber} color="primary">
                  <ApplicationIcon name={"filter" as ApplicationIconName} />
                </StyledBadge>
              </Box>
            ) : (
              <ApplicationIcon name={"filter" as ApplicationIconName} />
            )}
          </IconButton>
        </Tooltip>
      </Box>
    );
  }

  let sort = null;
  if (props.enableSorting) {
    const icon = sortOrder === "asc" ? "arrow-up" : sortOrder === "desc" ? "arrow-down" : null;
    const multiSort = props.api.getColumnState()?.filter((col) => col?.sort)?.length;
    sort = !!icon && (
      <Box sx={{ ml: 0.75, mt: 0.75 }}>
        <ApplicationIcon name={icon as ApplicationIconName} />
        {multiSort > 1 && <span>{props.column.getSortIndex()}</span>}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden"
      }}
    >
      <Box
        onClick={onSortRequested}
        sx={{
          display: "flex",
          alignItems: "center",
          width: sortOrder ? `calc(100% - 5px)` : "100%",
          ":hover": { cursor: props.enableSorting ? "pointer" : "default" }
        }}
      >
        <Box
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {props.displayName}
        </Box>
        {sort}
      </Box>
      {menu}
    </Box>
  );
};
