import React from "react";
import { Box } from "@mui/material";

const numberFormat = (number: number) => {
  return (number || number === 0) && number.toLocaleString("en-US", { minimumFractionDigits: 2 });
};

interface Props {
  label: React.ReactElement | string | React.ReactNode | JSX.Element;
  value: React.ReactElement | string | React.ReactNode | JSX.Element | number;
  isNumber?: boolean;
}

const RenderLabelValue = (props: Props) => {
  const { label, value, isNumber } = props;
  return (
    <>
      <Box component="label" sx={{ fontWeight: 600, fontSize: 12, pb: 0, color: "#333333" }}>
        {label}
      </Box>
      <Box sx={{ fontWeight: 400, fontSize: 14, pb: 2 }}>
        {isNumber ? numberFormat(value as number) : value}
      </Box>
    </>
  );
};

export default RenderLabelValue;
