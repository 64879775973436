import { useCallback, useState } from "react";
import { AcceleratorApi, ApiListResponse, HTTPError, SearchFilter, Tag } from "../../../";

type SearchParams = {
  searchTerm: string;
  filters?: SearchFilter<Tag>;
  from?: number;
  size?: number;
};

type SearchHookResponse = {
  loading: boolean;
  error: HTTPError | undefined;
  errorMessage: string;
  search: (searchParams: SearchParams) => Promise<ApiListResponse<Tag>>;
};

type SearchState = {
  loading: boolean;
  error: HTTPError | undefined;
  errorMessage: string | undefined;
};

export const useSearchTags = (): SearchHookResponse => {
  const [searchState, setSearchState] = useState<SearchState>({
    loading: false,
    error: undefined,
    errorMessage: undefined
  });

  const updateInternalState = (updates: SearchState) => {
    setSearchState((s) => ({ ...s, ...updates }));
  };

  const search = useCallback(async (params: SearchParams) => {
    try {
      updateInternalState({ loading: true, error: undefined, errorMessage: undefined });

      const result = await AcceleratorApi.Tags.search(params.searchTerm, params.filters, {
        from: params.from,
        size: params.size
      });

      updateInternalState({ loading: false, error: undefined, errorMessage: undefined });

      return result as ApiListResponse<Tag>;
    } catch (err) {
      const error: HTTPError = err;
      let errorString = "";

      try {
        const responseBody = await error.response.json();
        errorString = responseBody?.error;
      } catch {
        const text = await error.response?.text();

        if (text) {
          errorString = `${errorString}: ${text}`;
        }
      }
      updateInternalState({ loading: false, error: err, errorMessage: errorString });
    }
  }, []);

  return { ...searchState, search };
};
