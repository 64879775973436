import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import {
  AssetSubType,
  AssetSubTypeDocumentResponse,
  AssetSubTypeListResponse,
  HTTPError
} from "../../../types";
import { assetSubTypesListQueryKey, assetSubTypeByIdQueryKey } from "../query-cache";
import { useGetAssetSubTypeById } from "./useGetAssetSubTypeById";

export const useUpdateAssetSubType = (assetSubTypeId: string) => {
  const queryClient = useQueryClient();
  const { assetSubType } = useGetAssetSubTypeById(assetSubTypeId);
  const queryOptions: UseMutationOptions<
    AssetSubTypeDocumentResponse,
    HTTPError,
    Partial<AssetSubType>
  > = {
    mutationFn: (updates) =>
      AcceleratorApi.AssetSubTypes.patchAssetSubType(assetSubTypeId, assetSubType, updates),
    onSuccess: (assetSubType: AssetSubTypeDocumentResponse) => {
      // Invalidate equipment types query data.
      queryClient.invalidateQueries<AssetSubTypeListResponse>(assetSubTypesListQueryKey(), {
        exact: true
      });

      // Invalidate asset type by ID query data.
      queryClient.invalidateQueries<AssetSubTypeDocumentResponse>(
        assetSubTypeByIdQueryKey(assetSubType.data.id)
      );
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, data, error } = useMutation(queryOptions);

  return {
    updateAssetSubType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    assetSubType: data?.data,
    error
  };
};
