import React, { useEffect, useState, useImperativeHandle } from "react";
import { TreeItem, TreeView } from "@packages/theme-mui-v5";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { onChildChange } from "../utils";
import {
  PermissionsProps,
  PermissionsType,
  TreeNode
} from "../drawers/groups/EditGroupPermissions";

const BoxContainer = styled(Box)(() => ({
  "& .MuiTypography-subtitle2": {
    padding: "5px 0"
  },
  "& .child-node": {
    position: "relative",
    right: "10px"
  }
}));

const Permissions = React.forwardRef<PermissionsType, PermissionsProps>((props, ref) => {
  const { permissionsTree } = props;
  const [permissions, setPermissions] = useState<TreeNode[]>(permissionsTree);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setExpanded(permissionsTree.map((item) => item.nodeId));
  }, []);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleItemClick = (selected, nodes) => {
    setPermissions(onChildChange(permissions, selected, nodes));
  };

  useImperativeHandle(ref, () => ({
    getPermissions: () => permissions
  }));

  return (
    <BoxContainer>
      <TreeView expanded={expanded} onNodeToggle={handleToggle}>
        {permissions.map((data) => (
          <TreeItem checkable={false} node={data} key={data.nodeId}>
            {data.children.map((item) => (
              <TreeItem node={item} key={item.nodeId} handleSelect={handleItemClick} />
            ))}
          </TreeItem>
        ))}
      </TreeView>
    </BoxContainer>
  );
});

Permissions.displayName = "Permissions";

export default Permissions;
