import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetAnnotationReliabilityCategoryListResponse } from "../../../types";
import { assetAnnotationPlantStatusListQueryKey } from "../query-cache";

export const useGetAssetAnnotationPlantStatus = () => {
  const queryKey = assetAnnotationPlantStatusListQueryKey();

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetAnnotationReliabilityCategoryListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetNotes.getAssetAnnotationPlantStatus()
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetAnnotationPlantStatus: data?.data,
    error,
    refetch
  };
};
