import { UseQueryOptions, useQuery } from "react-query";
import { CommonDataApi, HTTPError, PermissionDocumentResponse } from "../../..";
import { permissionById } from "./query-cache";

/**
 * Get a permission by permissionId
 */
export const useGetPermissionById = (permissionTypeId: string) => {
  const queryOptions: UseQueryOptions<PermissionDocumentResponse, HTTPError> = {
    queryKey: permissionById(permissionTypeId),
    queryFn: () => CommonDataApi.Permissions.getById(permissionTypeId),
    enabled: !!permissionTypeId
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    permission: data && data.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};
