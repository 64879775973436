import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import PermissionForm, { PermissionFormRef } from "../../components/PermissionForm";
import { Permission, useGetPermissionById, useUpdatePermission } from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const EditPermissions = () => {
  const { id } = useParams<{ id: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedLocationId } = useLocalState();

  const navigate = useNavigate();
  const { permission, loading } = useGetPermissionById(id);
  const { updatePermission, loading: updateLoading } = useUpdatePermission(selectedLocationId, id);
  const ref = React.useRef<PermissionFormRef>(null);

  const onClose = () => {
    navigate("/admin/auth/permissions");
  };

  const handleSubmit = async (values: Permission) => {
    try {
      await updatePermission({ updatedPermission: values, permission });
      enqueueSnackbar("Permission updated successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const handleSave = () => {
    ref.current && ref.current.onSubmit();
  };

  return (
    <Drawer
      contentTitle="Permission"
      title="Edit Permission"
      onClose={onClose}
      hideContent={loading || !permission}
      loading={loading || !permission || updateLoading}
      actions={[
        { text: "Save", action: handleSave, disabled: updateLoading || loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      {!!permission && (
        <PermissionForm
          title="Permission"
          onSubmit={handleSubmit}
          permission={permission}
          ref={ref}
        />
      )}
    </Drawer>
  );
};

export default EditPermissions;
