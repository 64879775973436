/**
 * debounce util function - delaying the execution of a function until a certain amount of time has passed since the last time it was called.
 * @param {T} callback function will be called after waitFor time
 * @param {number} waitFor delay
 * @return {ReturnType<T>}
 */
export const debounce = <T extends (...args: unknown[]) => unknown>(
  callback: T,
  waitFor: number
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>): ReturnType<T> => {
    let result: unknown;
    timeout && clearTimeout(timeout);
    timeout = setTimeout(() => {
      result = callback(...args);
    }, waitFor);
    return result as ReturnType<T>;
  };
};
