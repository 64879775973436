import { Gradient } from "typescript-color-gradient";

export type Color = string;

/**
 * Utility to create a palette of gradient colors
 *
 * @param numberOfColors palette colors count
 * @returns array of color RGB hex codes
 */
export const generateColorPalette = (numberOfColors: number): Color[] => {
  return (
    new Gradient()
      // TODO Improve base colors for better UX
      .setGradient("#92f7db", "#ffb58a", "#fad2ea", "#5caff7", "#aaa0fa")
      .setNumberOfColors(numberOfColors)
      .getColors()
  );
};
