import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, NameplateLabelsListResponse } from "../../../types";
import { nameplateLabelsListQueryKey } from "../query-cache";

export const useGetNameplateLabels = () => {
  const queryKey = nameplateLabelsListQueryKey();

  const queryOptions: UseQueryOptions<NameplateLabelsListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.NameplateLabels.get()
  };

  const { isLoading, isFetching, isSuccess, isError, data, error } =
    useQuery<NameplateLabelsListResponse>(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    isFetching,
    nameplateLabels: data?.data,
    error
  };
};
