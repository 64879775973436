import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetNameplateDataListResponse } from "../../../types";
import { assetNameplateDataListQueryKey } from "../query-cache";

export const useGetAssetNameplateData = (
  assetId: string,
  { enabled }: { enabled?: boolean } = {}
) => {
  const queryKey = assetNameplateDataListQueryKey(assetId);

  const { isLoading, isSuccess, isError, data, error, refetch } =
    useQuery<AssetNameplateDataListResponse>({
      queryKey,
      queryFn: () => AcceleratorApi.AssetNameplateData.getByAssetId(assetId),
      enabled
    });

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetNameplateData: data?.data || [],
    error,
    refetch
  };
};
