import { FC, useRef } from "react";
import { Box, Alert } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import { Group, useGroupById, useUpdateGroup } from "@packages/service-api";
import GroupForm, { GroupFormRef } from "../../components/GroupForm";

const EditGroup: FC = () => {
  const { id } = useParams<{ id: string }>();
  const ref = useRef<GroupFormRef>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { group } = useGroupById(id);
  const { updateGroup, loading, isError } = useUpdateGroup(id);

  const handleSubmit = async (values: Partial<Group>) => {
    await updateGroup({ updatedGroup: { ...group, ...values }, group });
  };

  const onClose = () => {
    navigate("/admin/auth/groups");
  };

  const handleSave = async () => {
    try {
      ref.current && ref.current.onSubmit();

      enqueueSnackbar("Group updated successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  return (
    <Drawer
      contentTitle="Edit Group"
      title="Edit Group"
      onClose={onClose}
      loading={loading || !group}
      actions={[
        { text: "Save", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      {isError && (
        <Box mb={2}>
          <Alert severity="error">
            Unfortunately, We are unable to save your changes. Please try again.
          </Alert>
        </Box>
      )}
      {group && <GroupForm group={group} onSubmit={handleSubmit} ref={ref} />}
    </Drawer>
  );
};

export default EditGroup;
