import * as React from "react";
import { Box, Tooltip } from "@mui/material";
import copy from "clipboard-copy";
import { ApplicationIcon } from "../icons";

type Props = {
  children: React.ReactNode;
  value: string;
};

export const CopyButton: React.FC<Props> = (props) => {
  const { children, value } = props;

  const [showIcon, setShowIcon] = React.useState<boolean>(false);
  const [copied, setCopied] = React.useState<boolean>(false);

  const onCopyClick = () => {
    setCopied(true);
    copy(value);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box
      onMouseOver={() => setShowIcon(true)}
      onMouseOut={() => setShowIcon(false)}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& :hover": {
          cursor: "pointer"
        }
      }}
    >
      <Box>{children}</Box>

      <Box
        sx={{
          visibility: showIcon ? "visible" : "hidden"
        }}
      >
        <Tooltip arrow title={copied ? "Copied!" : "Copy to clipboard"}>
          <Box onClick={onCopyClick}>
            <ApplicationIcon name="copy" />
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};
