import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { SubArea, SubAreaResponse, SubAreaListResponse, HTTPError } from "../../../types";

import { AcceleratorApi } from "../../..";
import { subAreasByIdQueryKey, subAreasListQueryKey } from "../query-cache";

interface Variables {
  previousSubArea: SubArea;
  subArea: Partial<SubArea>;
}

export const useUpdateSubArea = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<SubAreaResponse, HTTPError, Variables> = {
    mutationFn: ({ previousSubArea, subArea }: Variables) =>
      AcceleratorApi.SubAreas.update(previousSubArea, subArea),
    onSuccess: (data: SubAreaResponse) => {
      queryClient.setQueryData<SubAreaListResponse | undefined>(
        subAreasListQueryKey(),
        (currentState: SubAreaListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => (state.id === data.data.id ? data.data : state))
          };
        }
      );
      queryClient.setQueryData(subAreasByIdQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, data, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    nameplateLabel: data?.data,
    isError,
    error,
    loading: isLoading,
    isSuccess,
    updateSubArea: mutateAsync
  };
};
