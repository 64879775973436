import { useMutation, UseMutationOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { SteadyState } from "../../../types/accelerator/steady-state";

export function useUpdateSteadyStateMutation(
  options: UseMutationOptions<unknown, unknown, { id: string; steadyState: SteadyState }> = {}
) {
  return useMutation({
    mutationFn: ({ id, steadyState }) => AcceleratorApi.SteadyState.update(id, steadyState),
    ...options
  });
}
