import * as React from "react";
import { Box } from "@mui/material";

export type AutoHeightProps = {
  children?: React.ReactNode;
  minHeight?: number;
  offsetHeight: number;
};

export const AutoHeight = (props: AutoHeightProps) => {
  const { minHeight, children, offsetHeight } = props;

  const ref = React.useRef(null);

  return (
    <Box
      ref={ref}
      sx={{
        minHeight,
        height: `calc(100vh - ${ref.current?.getBoundingClientRect()?.top + offsetHeight}px)`
      }}
    >
      {children}
    </Box>
  );
};

AutoHeight.defaultProps = {
  minHeight: 300,
  offsetHeight: 17
};
