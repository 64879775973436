import { isEmpty } from "./isEmpty";

export const indexBy = <T>(list: Array<T>, propertyName: keyof T): Record<string, T> => {
  if (isEmpty(list)) {
    return {};
  }

  return list.reduce<Record<string, T>>((aggregate: Record<string, T>, next: T) => {
    const key = String(next[propertyName]);
    if (!isEmpty(key)) {
      aggregate[key] = next;
    }
    return aggregate;
  }, {});
};
