import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { TagWeights, TagWeightsResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";
import { tagWeightsByIdQueryKey } from "../query-cache";

type Variables = {
  previousTagWeights: TagWeights;
  tagWeights: Partial<TagWeights>;
};

export const useUpdateTagWeights = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<TagWeightsResponse, HTTPError, Variables> = {
    mutationFn: ({ previousTagWeights, tagWeights }: Variables) =>
      AcceleratorApi.TagWeights.patchTagWeights(previousTagWeights, tagWeights),
    onSuccess: (res) => {
      queryClient.setQueryData(tagWeightsByIdQueryKey(res.data.id), () => res);
    }
  };

  const { mutateAsync, isLoading, error, data, isSuccess } = useMutation(queryOptions);

  return {
    tagWeights: data?.data,
    error: error,
    loading: isLoading,
    isSuccess,
    updateTagWeights: mutateAsync
  };
};
