import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetNoteResponse } from "../../../types";
import { assetNoteByIdQueryKey } from "../query-cache";

export const useGetAssetNoteById = (assetNoteId: string) => {
  const queryKey = assetNoteByIdQueryKey(assetNoteId);

  const queryOptions: UseQueryOptions<AssetNoteResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetNotes.getById(assetNoteId),
    enabled: Boolean(assetNoteId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetNote: data?.data,
    error
  };
};
