import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import {
  AssetTemplate,
  HTTPError,
  AssetTemplateDocumentResponse,
  AssetTemplateListResponse
} from "../../../types";
import { templatesSearchListQueryKey } from "../query-cache";

export const useCreateAssetTemplate = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<
    AssetTemplateDocumentResponse | AssetTemplateListResponse,
    HTTPError,
    Partial<AssetTemplate> | Partial<AssetTemplate>[]
  > = {
    mutationFn: (template) => AcceleratorApi.Templates.create(template),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: templatesSearchListQueryKey()
      });
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    createTemplate: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
