import React from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationIcon, PageToolbar } from "@packages/theme-mui-v5";

interface Props {
  tab: string;
}

const GroupsToolbar = () => {
  const navigate = useNavigate();
  return (
    <PageToolbar
      title="Groups"
      rightActions={[
        {
          type: "button",
          title: "Add Group",
          variant: "contained",
          startIcon: <ApplicationIcon name="plus" />,
          onClick: () => navigate("/admin/auth/groups/create")
        }
      ]}
    />
  );
};

const UsersToolbar = () => {
  const navigate = useNavigate();
  return (
    <PageToolbar
      title="Users"
      rightActions={[
        {
          type: "button",
          title: "Add User",
          variant: "contained",
          startIcon: <ApplicationIcon name="plus" />,
          onClick: () => navigate("/admin/auth/users/add")
        }
      ]}
    />
  );
};

const PermissionsToolbar = () => {
  const navigate = useNavigate();
  return (
    <PageToolbar
      title="Permissions"
      rightActions={[
        {
          type: "button",
          title: "Add Permission",
          variant: "contained",
          startIcon: <ApplicationIcon name="plus" />,
          onClick: () => navigate("/admin/auth/permissions/create")
        }
      ]}
    />
  );
};

const LandingPageToolbar = (props: Props) => {
  const { tab } = props;
  switch (tab) {
    case "groups":
      return <GroupsToolbar />;
    case "permissions":
      return <PermissionsToolbar />;
    case "users":
      return <UsersToolbar />;
    default:
      return null;
  }
};

export default LandingPageToolbar;
