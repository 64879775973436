import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, AssetPropertyNoteResponse } from "../../../types";
import { assetPropertyNoteByIdQueryKey } from "../query-cache";

export const useGetAssetPropertyNoteById = (assetPropertyNoteId: string) => {
  const queryKey = assetPropertyNoteByIdQueryKey(assetPropertyNoteId);

  const queryOptions: UseQueryOptions<AssetPropertyNoteResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.AssetPropertyNotesApi.getById(assetPropertyNoteId),
    enabled: Boolean(assetPropertyNoteId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    assetPropertyNote: data?.data,
    error
  };
};
