import { useQuery } from "react-query";
import { TungstenDataSourceListResponse } from "../../../types";
import { MaterialBalanceApi } from "../../..";

export const useSearchTungstenTags = (searchTerm: string, systemName: string) => {
  const { data, error, isLoading } = useQuery<TungstenDataSourceListResponse, Error>(
    ["tungsten-tagname", "data-sources", searchTerm, systemName],
    () => MaterialBalanceApi.Tungsten.DataSources.getTungstenTags(systemName, searchTerm),
    {
      enabled: Boolean(searchTerm.length > 2 && systemName)
    }
  );

  return {
    tungstenTags: data ? data.data : [],
    error: error,
    loading: isLoading
  };
};
