import { useQuery, UseQueryOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { SteadyStateResponse } from "../../../types/accelerator/steady-state";
import { steadyStateGetByIdQueryKey } from "../query-cache";

export function useGetSteadyStateByIdQuery(
  id: string,
  options: Omit<UseQueryOptions<SteadyStateResponse>, "queryKey" | "queryFn"> = {}
) {
  return useQuery({
    queryKey: steadyStateGetByIdQueryKey(id),
    queryFn: ({ queryKey }) => AcceleratorApi.SteadyState.getById(queryKey[3] as string),
    ...options
  });
}
