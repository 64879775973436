import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteDataSource = () => {
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (dataSourceId: string) => AcceleratorApi.DataSource.deleteDataSource(dataSourceId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteDataSource: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
