import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { CommonDataApi, HTTPError, PeopleDocumentResponse, PeopleListResponse } from "../../..";
import { groupMembersByGroupId } from "../groups/query-cache";

interface Variables {
  members: string[];
  groupIds: string[];
}

/**
 * Add one or more users to one ore more groups
 */
export const useAddUsersToGroups = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    (PeopleListResponse | PeopleDocumentResponse)[],
    HTTPError,
    Variables
  > = {
    mutationFn: ({ members, groupIds }: Variables) => {
      return Promise.all(
        groupIds.map((groupId) => {
          return CommonDataApi.Groups.addGroupMembers(groupId, members);
        })
      );
    },
    onSuccess: (_response, variables) => {
      variables.groupIds.forEach((groupId) => {
        queryClient.invalidateQueries(groupMembersByGroupId(groupId));
      });
    }
  };

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(queryOptions);

  return {
    addGroupMembers: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
