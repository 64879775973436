import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  CommonDataApi,
  HTTPError,
  PermissionDocumentResponse,
  PermissionListResponse
} from "../../..";
import { permissionsOfGroupByGroupId } from "./query-cache";

/**
 * Hook to add permissions to a group
 */
export const useAddGroupPermissions = (groupId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    PermissionListResponse | PermissionDocumentResponse,
    HTTPError,
    string[]
  > = {
    onSuccess: (response) => {
      queryClient.setQueryData<PermissionListResponse | undefined>(
        permissionsOfGroupByGroupId(groupId),
        (prevState) => {
          if (!prevState) return;
          let nextData = [...prevState.data];
          let nextTotal = prevState.total;

          if (Array.isArray(response.data)) {
            nextData = [...prevState.data, ...(response as PermissionListResponse).data];
            nextTotal = prevState.total + (response as PermissionListResponse).data.length;
          } else {
            nextData = [...prevState.data, (response as PermissionDocumentResponse).data];
            nextTotal = prevState.total + 1;
          }

          return {
            ...prevState,
            data: nextData,
            total: nextTotal
          };
        }
      );
    },
    mutationFn: (permissions: string[]) =>
      CommonDataApi.Groups.addGroupPermissions(groupId, permissions)
  };

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(queryOptions);

  return {
    addGroupPermissions: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
