import { useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetTypeListResponse, AssetTypeDocumentResponse } from "../../../types";
import { assetTypesListQueryKey, assetTypeByIdQueryKey } from "../query-cache";

export const useDeleteAssetType = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (assetTypeId: string) => {
      return AcceleratorApi.AssetTypes.deleteAssetType(assetTypeId);
    },
    onSuccess: (_response, assetTypeId) => {
      // Invalidate assetTypes query data.
      queryClient.invalidateQueries<AssetTypeListResponse>(assetTypesListQueryKey(), {
        exact: true
      });

      // Invalidate assetType by ID query data.
      queryClient.invalidateQueries<AssetTypeDocumentResponse>(assetTypeByIdQueryKey(assetTypeId));
    }
  });

  return {
    deleteAssetType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
