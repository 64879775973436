import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, SubAreaListResponse } from "../../../types";
import { subAreasListQueryKey } from "../query-cache";

export const useDeleteSubArea = () => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (subAreaId: string) => AcceleratorApi.SubAreas.delete(subAreaId),
    onSuccess: (_, id) => {
      queryClient.setQueryData<SubAreaListResponse | undefined>(
        subAreasListQueryKey(),
        (currentState: SubAreaListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.filter((item) => item.id !== id)
          };
        }
      );
    }
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteSubArea: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
