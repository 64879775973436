import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import {
  CommonDataApi,
  Permission,
  PermissionDocumentResponse,
  PermissionListResponse,
  HTTPError
} from "../../..";

import { permissionById, permissionsOfLocation } from "./query-cache";

interface Variables {
  updatedPermission: Permission;
  permission: Permission | Partial<Permission>;
}

/**
 * Update a permission
 */
export const useUpdatePermission = (permissionId: string, locationId: string) => {
  const queryClient = useQueryClient();
  const queryOptions: UseMutationOptions<PermissionDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ updatedPermission, permission }: Variables) =>
      CommonDataApi.Permissions.update(permissionId, updatedPermission, permission as Permission),
    onSuccess: (node: PermissionDocumentResponse) => {
      queryClient.setQueryData<PermissionListResponse>(
        permissionsOfLocation(locationId),
        (prevState: PermissionListResponse) => {
          if (!prevState) return undefined;

          prevState.data = prevState.data.map((state) =>
            state.permissionTypeId === node.data.permissionTypeId ? node.data : state
          );

          return {
            ...prevState,
            data: [...prevState.data]
          };
        }
      );
      queryClient.setQueryData(permissionById(node.data.permissionTypeId), () => node);
    }
  };
  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    updatePermission: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
