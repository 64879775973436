import { useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetSubTypeListResponse, AssetSubTypeDocumentResponse } from "../../../types";
import { assetSubTypesListQueryKey, assetSubTypeByIdQueryKey } from "../query-cache";

export const useDeleteAssetSubType = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (assetSubTypeId: string) => {
      return AcceleratorApi.AssetSubTypes.deleteAssetSubType(assetSubTypeId);
    },
    onSuccess: (_response, assetSubTypeId) => {
      // Invalidate assetSubTypes query data.
      queryClient.invalidateQueries<AssetSubTypeListResponse>(assetSubTypesListQueryKey(), {
        exact: true
      });

      // Invalidate assetSubType by ID query data.
      queryClient.invalidateQueries<AssetSubTypeDocumentResponse>(
        assetSubTypeByIdQueryKey(assetSubTypeId)
      );
    }
  });

  return {
    deleteAssetSubType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
