export const peopleList = () => ["common-data", "people", "list"];

export const peopleById = (peopleId: string) => ["common-data", "people", "single", peopleId];

export const peopleOfSite = (applicationId: string, siteId: string) => [
  "common-data",
  "people",
  siteId,
  applicationId
];
export const peopleOfLocation = (applicationId: string, siteId: string, locationId: string) => [
  "common-data",
  "people",
  "by-location",
  siteId,
  applicationId,
  locationId
];

export const peopleOfApplication = (applicationId: string) => [
  "common-data",
  "people",
  "by-application",
  applicationId
];

export const peopleOfApplications = (applicationIds: string[]) => [
  "common-data",
  "people",
  "by-applications",
  applicationIds?.join()
];

export const searchPeople = (searchTerm: string, siteId?: string) => [
  "common-data",
  "people",
  "search",
  searchTerm,
  siteId || "all-sites"
];
