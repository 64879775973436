import React from "react";
import { TreeItem as MUITreeItem } from "@mui/lab";
import { Typography, FormControlLabel, Checkbox } from "@mui/material";

export interface TreeNode {
  label?: string;
  parent?: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  selected?: boolean;
  nodeId?: string;
  parentId?: string;
}

interface TreeItemProps extends TreeNode {
  handleSelect?: (checked: boolean, node: TreeNode) => void;
  node: TreeNode;
  parentId?: string;
  children?: React.ReactNode;
  checkable?: boolean;
}

const TreeItem = (props: TreeItemProps) => {
  const { node, checkable, handleSelect, children } = props;
  const { label, parent, selected, indeterminate, disabled, nodeId } = node;

  return (
    <MUITreeItem
      key={nodeId}
      nodeId={nodeId}
      className={parent ? "parent-node" : "child-node"}
      onClick={(event) => event.stopPropagation()}
      label={
        checkable ? (
          <FormControlLabel
            label={<Typography variant={parent ? "subtitle2" : "body2"}>{label}</Typography>}
            control={
              <Checkbox
                indeterminate={indeterminate}
                checked={selected}
                disabled={disabled}
                onClick={(event) => event.stopPropagation()}
                onChange={(event, checked) => handleSelect(checked, node)}
                name={label}
              />
            }
          />
        ) : (
          <Typography variant={parent ? "subtitle2" : "body2"}>{label}</Typography>
        )
      }
    >
      {children}
    </MUITreeItem>
  );
};

TreeItem.defaultProps = {
  checkable: true
};

export default TreeItem;
