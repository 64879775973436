import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { WithLoader, useMediaQuery } from "@packages/theme-mui-v5";
import { ICellRendererParams, DataGrid, ColDef, RowActionsCell } from "@packages/data-grid";
import { useGetPermissionsByApplicationId } from "@packages/service-api";
import useLocalState from "../hooks/useLocalState";

const PermissionsTable = () => {
  const { applicationId } = useLocalState();

  const { permissions, loading } = useGetPermissionsByApplicationId(applicationId);
  const navigate = useNavigate();
  const { smUp } = useMediaQuery();

  const columnDefs: ColDef[] = React.useMemo(() => {
    return [
      { headerName: "", field: "module", rowGroupIndex: 0, hide: true },
      { headerName: "Name", field: "name", sort: "asc" },
      ...(smUp ? [{ field: "description", headerName: "Description" }] : []),
      {
        field: "permissionTypeId",
        headerName: "",
        maxWidth: 85,
        cellRenderer: (params: ICellRendererParams) => {
          if (params.node.group) return null;
          return (
            <RowActionsCell
              actions={[
                {
                  icon: "edit",
                  color: "primary",
                  tooltip: "Edit Permission",
                  action: () => navigate(`/admin/auth/permissions/${params.value}/edit`)
                },
                {
                  icon: "delete",
                  color: "error",
                  tooltip: "Delete Permission",
                  action: () => navigate(`/admin/auth/permissions/${params.value}/delete`)
                }
              ]}
            />
          );
        }
      }
    ];
  }, [smUp]);

  const autoGroupColumnDef = React.useMemo<ColDef>(() => {
    return {
      headerName: "Permissions",
      cellClass: "primary-text"
    };
  }, []);

  return (
    <Box minHeight={400} position="relative">
      <WithLoader loading={loading}>
        <DataGrid
          loading={loading}
          autoGroupColumnDef={autoGroupColumnDef}
          columnDefs={columnDefs}
          rowData={permissions}
        />
      </WithLoader>
    </Box>
  );
};

export default PermissionsTable;
