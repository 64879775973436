import { createContext, ReactNode, useContext, useMemo } from "react";
import { useUserProfile } from "../hooks/auth/useUserProfile";
import { Auth } from "..";
import { CRUDPermissionIds } from "@nutrien-operations/config";

/**
 * A map of the current user at the current site's permission ability to perform a certain action.
 * CRUD permission ids are defined by closest `PermissionsProvider`.
 *
 * @example
 * const permissions = usePermissionsContext();
 *
 * if (permissions.canDelete) {
 *   actions.push({ ... delete ... })
 * }
 *
 * @example
 * ```json
 * {
 *   canCreate: true,
 *   canRead: true,
 *   canUpdate: true,
 *   canDelete: true,
 * }
 * ```
 **/
export type PermissionsContext = {
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
};

export const PermissionsContext = createContext<PermissionsContext | undefined>(undefined);

export type PermissionsProviderProps = {
  children: ReactNode;
  applicationId: string;
  crudPermissionIds: CRUDPermissionIds;
  locationId?: string;
  skipSiteLocationCheck?: boolean;
};

export function PermissionsProvider({
  children,
  crudPermissionIds,
  applicationId,
  locationId,
  skipSiteLocationCheck
}: PermissionsProviderProps) {
  const profile = useUserProfile();
  const siteId = useMemo(() => profile?.preferences?.selectedSiteId || profile?.siteId, [profile]);

  if (!siteId) {
    throw new Error("A `siteId` is required to check permissions.");
  }

  const permissionBase = { applicationId, siteId, locationId, skipSiteLocationCheck };

  const value: PermissionsContext = {
    canCreate: Auth.checkPermission({ ...permissionBase, permissions: [crudPermissionIds.create] }),
    canRead: Auth.checkPermission({ ...permissionBase, permissions: [crudPermissionIds.read] }),
    canUpdate: Auth.checkPermission({ ...permissionBase, permissions: [crudPermissionIds.update] }),
    canDelete: Auth.checkPermission({ ...permissionBase, permissions: [crudPermissionIds.delete] })
  };

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}

/**
 * Returns the current user's permissions at the current site based on the closest `PermissionsProvider`.
 * Derives if the user can create, read, update, or delete based on the provided `applicationId` and optional `locationId`.
 * This can be used to conditionally render components or actions based on the user's permissions.
 *
 * @example
 * const permissions = usePermissionsContext();
 *
 * if (permissions.canDelete) {
 *  actions.push({ ... delete ... })
 * }
 */
export function usePermissionContext() {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error("`usePermissionContext` must be used within a `PermissionsProvider`");
  }

  return context;
}
