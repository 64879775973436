import { useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { AssetType, AssetTypeListResponse } from "../../../types";
import { assetTypesListQueryKey } from "../query-cache";

export const useCreateAssetType = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
    mutationFn: (assetType: Partial<AssetType>) => {
      return AcceleratorApi.AssetTypes.createAssetType(assetType);
    },
    onSuccess: () => {
      // Invalidate asset types query data.
      queryClient.invalidateQueries<AssetTypeListResponse>(assetTypesListQueryKey(), {
        exact: true
      });
    }
  });

  return {
    createAssetType: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
