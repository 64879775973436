import { UseMutationOptions, useMutation } from "react-query";
import { AssetProperty, AssetPropertyDocumentResponse, HTTPError } from "../../../types";
import { AcceleratorApi } from "../../..";

type Variables = {
  previousAssetProperty: AssetProperty;
  assetProperty: Partial<AssetProperty>;
};

/**
 * Hook to update an Persona AssetProperty
 */
export const useUpdatePersonaAssetProperty = () => {
  const queryOptions: UseMutationOptions<AssetPropertyDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ previousAssetProperty, assetProperty }: Variables) =>
      AcceleratorApi.AssetProperties.updatePersonaAssetProperty(
        previousAssetProperty,
        assetProperty
      )
  };

  const { mutateAsync, isLoading, error, data } = useMutation(queryOptions);

  return {
    assetProperty: data?.data,
    error: error,
    loading: isLoading,
    updatePersonaAssetProperty: mutateAsync
  };
};
