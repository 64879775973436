import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi, HTTPError, Note, NoteResponse } from "../../..";

export const useUpdateNotes = () => {
  const queryOptions: UseMutationOptions<NoteResponse[], HTTPError, Note[]> = {
    mutationFn: (notes: Note[]) => {
      return Promise.all(
        notes.map((note: Note) => {
          return AcceleratorApi.TagMappings.updateNotes(note);
        })
      );
    }
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    notes: data,
    updateNotes: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
