import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, ProductionUnitResponse } from "../../../types";
import { productionUnitsQueryKey } from "../query-cache";

export const useGetProductionUnitById = (productionUnitId: string) => {
  const queryKey = productionUnitsQueryKey(productionUnitId);

  const queryOptions: UseQueryOptions<ProductionUnitResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.ProductionUnits.getById(productionUnitId)
  };

  const { isLoading, isSuccess, isError, data, error } = useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    productionUnit: data?.data,
    error
  };
};
