import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import { SubArea, SubAreaResponse, SubAreaListResponse, HTTPError } from "../../../types";

import { subAreasByIdQueryKey, subAreasListQueryKey } from "../query-cache";

export const useCreateSubArea = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<SubAreaResponse, HTTPError, Partial<SubArea>> = {
    mutationFn: (subAreaId) => AcceleratorApi.SubAreas.create(subAreaId),
    onSuccess: (data: SubAreaResponse) => {
      queryClient.setQueryData<SubAreaListResponse | undefined>(
        subAreasListQueryKey(),
        (currentState: SubAreaListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [data.data, ...currentState.data]
          };
        }
      );
      queryClient.setQueryData(subAreasByIdQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createSubArea: mutateAsync,
    subArea: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
