import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError, Note, NoteListResponse } from "../../../types";

export const useAddNotes = () => {
  const queryOptions: UseMutationOptions<NoteListResponse, HTTPError, Note[]> = {
    mutationFn: (notes: Note[]) => AcceleratorApi.TagMappings.addNotes(notes)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    addNotes: mutateAsync,
    notes: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
