import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import {
  CommonDataApi,
  Group,
  GroupDocumentResponse,
  GroupListResponse,
  HTTPError
} from "../../..";

import { groupsByLocationId } from "./query-cache";

/**
 * Hook to create a new group
 */
export const useCreateGroup = (applicationId: string, locationId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<GroupDocumentResponse, HTTPError, Group> = {
    onSuccess: (response: GroupDocumentResponse) => {
      queryClient.setQueryData<GroupListResponse | undefined>(
        groupsByLocationId(applicationId, locationId),
        (prevState) => {
          if (!prevState)
            return {
              data: [response.data],
              hasMore: false,
              total: 1
            };

          return { ...prevState, data: [...prevState.data, response.data] };
        }
      );
    },
    mutationFn: (group: Group) =>
      CommonDataApi.Groups.create({ applicationId, locationId, ...group })
  };

  const { mutateAsync, isLoading, isError, data, isSuccess } = useMutation(queryOptions);

  return {
    groupData: data,
    createGroup: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
