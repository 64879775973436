import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Drawer, useSnackbar } from "@packages/theme-mui-v5";
import PermissionForm, { PermissionFormRef } from "../../components/PermissionForm";
import { Permission, useCreatePermission } from "@packages/service-api";
import useLocalState from "../../hooks/useLocalState";

const defaultPermission = {
  name: "",
  description: "",
  module: "",
  isEnabled: true
};

const AddPermissions = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ref = React.useRef<PermissionFormRef>(null);
  const { applicationId } = useLocalState();
  const { createPermission, loading } = useCreatePermission(applicationId);

  const onClose = () => {
    navigate("/admin/auth/permissions");
  };

  const handleSubmit = async (values: Permission) => {
    try {
      await createPermission(values);

      enqueueSnackbar("Permission created successfully.", {
        variant: "success"
      });

      onClose();
    } catch {
      enqueueSnackbar("Unfortunately, We are unable to save your changes. Please try again.", {
        variant: "error"
      });
    }
  };

  const handleSave = () => {
    ref.current && ref.current.onSubmit();
  };

  return (
    <Drawer
      contentTitle="Permission"
      title="Add Permission"
      onClose={onClose}
      loading={loading}
      actions={[
        { text: "Add Permission", action: handleSave, disabled: loading },
        { text: "Cancel", action: onClose }
      ]}
    >
      <PermissionForm
        title="Permission"
        onSubmit={handleSubmit}
        permission={defaultPermission}
        ref={ref}
      />
    </Drawer>
  );
};

export default AddPermissions;
