import { useMemo, FC } from "react";
import { Box, Typography } from "@mui/material";
import { DropzoneOptions, useDropzone } from "react-dropzone";

type Props = {
  addFilesMessage?: string;
  dropzoneOptions?: DropzoneOptions;
  onChange: (files: File[]) => void;
};

const FileDropzone: FC<Props> = (props) => {
  const { addFilesMessage, dropzoneOptions = {}, onChange } = props;

  const handleOnDrop = (accepted: File[]) => {
    onChange(accepted);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 2,
    ...dropzoneOptions,
    onDropAccepted: handleOnDrop
  });

  const renderedMessage = useMemo(() => {
    if (acceptedFiles.length === 0) {
      return addFilesMessage || "drop some files here, or click to select files";
    }

    return (
      <ul>
        {acceptedFiles.map((file, i) => (
          <li key={i}>
            <Typography>{file.name}</Typography>
          </li>
        ))}
      </ul>
    );
  }, [acceptedFiles, addFilesMessage]);

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "grey.100",
        border: "1px dashed",
        borderColor: "grey.300",
        display: "flex",
        justifyContent: "center",
        minHeight: 200,
        p: 4,
        "&:hover": {
          cursor: "pointer"
        }
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {renderedMessage}
    </Box>
  );
};

export default FileDropzone;
