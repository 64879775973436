import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import {
  CommonDataApi,
  HTTPError,
  PeopleDocumentResponse,
  PeopleListResponse,
  sortByKey
} from "../../..";
import { groupMembersByGroupId } from "./query-cache";

/**
 * Adds members to a specified group.
 */
export const useAddGroupMembers = (groupId: string) => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    PeopleListResponse | PeopleDocumentResponse,
    HTTPError,
    string[]
  > = {
    onSuccess: (response) => {
      queryClient.setQueryData<PeopleListResponse | undefined>(
        groupMembersByGroupId(groupId),
        (prevState) => {
          if (Array.isArray(response.data)) {
            const typedResponse = response as PeopleListResponse;

            return {
              ...prevState,
              data: sortByKey([...prevState.data, ...typedResponse.data], "firstName"),
              total: prevState.total + typedResponse.data.length
            };
          }

          const typedResponse = response as PeopleDocumentResponse;

          return {
            ...prevState,
            data: sortByKey([...prevState.data, typedResponse.data], "firstName"),
            total: prevState.total + 1
          };
        }
      );
    },
    mutationFn: (members: string[]) => CommonDataApi.Groups.addGroupMembers(groupId, members)
  };

  const { mutateAsync, isLoading, isError, isSuccess } = useMutation(queryOptions);

  return {
    addGroupMembers: mutateAsync,
    loading: isLoading,
    isError,
    isSuccess
  };
};
