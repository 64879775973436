import { useMutation, UseMutationOptions } from "react-query";

import { AcceleratorApi } from "../../..";

export function useDeleteAssetPersonaTemplateMutation<
  Options extends UseMutationOptions<void, unknown, string>
>(options?: Omit<Options, "mutationFn">) {
  return useMutation({
    ...options,
    mutationFn: (templateId) => {
      return AcceleratorApi.Templates.deletePersonaTemplate(templateId);
    }
  });
}
