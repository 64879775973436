import { createContext, ReactNode, useContext } from "react";
import { CRUDPermissionIds } from "@nutrien-operations/config";

type CRUDDefinitions = Array<{ label: string; crud: CRUDPermissionIds }>;

export type ApplicationAccessPermissions = {
  cruds?: CRUDDefinitions;
};

const ApplicationAccessPermissionsContext = createContext<ApplicationAccessPermissions | undefined>(
  undefined
);

export type ApplicationAccessPermissionsProviderProps = ApplicationAccessPermissions & {
  children: ReactNode;
};

/**
 * If defined above `ApplicationAccessManagement`, the permissions will display in a CRUD format,
 * instead of a linear list of checkbox permissions.
 *
 * If `peronas` is defined, those matching permissions will display under a "Personas" heading.
 *
 * Any permissions pulled not found under `cruds` or `personas` will be displayed under a "Other" heading.
 */
export function ApplicationAccessPermissionsProvider({
  children,
  cruds
}: ApplicationAccessPermissionsProviderProps) {
  return (
    <ApplicationAccessPermissionsContext.Provider value={{ cruds }}>
      {children}
    </ApplicationAccessPermissionsContext.Provider>
  );
}

export function useApplicationAccessPermissionsContext() {
  const context = useContext(ApplicationAccessPermissionsContext);

  if (!context) {
    return false;
  }

  return context;
}
