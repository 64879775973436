import * as React from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormGroup } from "@mui/material";
import { TextField } from "@packages/theme-mui-v5";
import { Group } from "@packages/service-api";

interface GroupForm {
  group?: Group | Partial<Group>;
  onSubmit?: (formFields: unknown) => void;
  onClose?: () => void;
}

export type GroupFormRef = {
  onSubmit: () => void;
};

const groupSchema = yup.object().shape({
  groupName: yup.string().required("Name is required"),
  description: yup.string()
});

const GroupForm = React.forwardRef<GroupFormRef, GroupForm>((props, ref) => {
  const { group, onSubmit } = props;
  const { groupName, description } = group;
  const { handleSubmit, control } = useForm<Group | Partial<Group>>({
    defaultValues: {
      groupName,
      description
    },
    resolver: yupResolver(groupSchema)
  });

  React.useImperativeHandle(ref, () => ({
    onSubmit: handleSubmit(onSubmit)
  }));

  return (
    <FormGroup onSubmit={handleSubmit(onSubmit)}>
      <TextField name="groupName" label="Name" control={control} required />
      <TextField name="description" label="Description" control={control} />
    </FormGroup>
  );
});

GroupForm.displayName = "GroupForm";

export default GroupForm;
