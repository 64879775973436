import { UseMutationOptions, useMutation, useQueryClient } from "react-query";
import { Plant, PlantsListResponse, PlantDocumentResponse, HTTPError } from "../../../types";

import { AcceleratorApi } from "../../..";
import { plantQueryKey, plantsListQueryKey } from "../query-cache";

interface Variables {
  previousPlant: Plant;
  plant: Partial<Plant>;
}

export const useUpdatePlant = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<PlantDocumentResponse, HTTPError, Variables> = {
    mutationFn: ({ previousPlant, plant }: Variables) =>
      AcceleratorApi.Plants.update(previousPlant, plant),
    onSuccess: (data: PlantDocumentResponse) => {
      queryClient.setQueryData<PlantsListResponse | undefined>(
        plantsListQueryKey(),
        (currentState: PlantsListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: currentState.data.map((state) => (state.id === data.data.id ? data.data : state))
          };
        }
      );
      queryClient.setQueryData(plantQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, data, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    plant: data?.data,
    isError,
    error,
    loading: isLoading,
    isSuccess,
    updatePlant: mutateAsync
  };
};
