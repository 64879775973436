import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import {
  HTTPError,
  NameplateLabel,
  NameplateLabelsDocumentResponse,
  NameplateLabelsListResponse
} from "../../../types";
import { nameplateLabelQueryKey, nameplateLabelsListQueryKey } from "../query-cache";

export const useCreateNameplateLabel = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    NameplateLabelsDocumentResponse,
    HTTPError,
    Partial<NameplateLabel>
  > = {
    mutationFn: (nameplateLabel) => AcceleratorApi.NameplateLabels.create(nameplateLabel),
    onSuccess: (data: NameplateLabelsDocumentResponse) => {
      queryClient.setQueryData<NameplateLabelsListResponse | undefined>(
        nameplateLabelsListQueryKey(),
        (currentState: NameplateLabelsListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [data.data, ...currentState.data]
          };
        }
      );
      queryClient.setQueryData(nameplateLabelQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createNameplateLabel: mutateAsync,
    dataSource: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
