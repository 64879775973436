import { FC } from "react";
import { LinearProgress } from "@mui/material";
import { ConfirmProps } from "./ConfirmProvider";

import { Dialog } from "../Dialog";

export type ConfirmDialogProps = ConfirmProps & {
  title: NonNullable<string>;
  message: NonNullable<string>;
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  message,
  isConfirming,
  onConfirm,
  onCancel,
  isOpen
}: ConfirmDialogProps): JSX.Element => (
  <Dialog
    isDraggable
    disableBackdropClick
    open={isOpen}
    title={title}
    bodyText={message}
    actions={[
      {
        text: "Confirm",
        action: onConfirm,
        disabled: isConfirming,
        loading: isConfirming,
        variant: "contained"
      },
      {
        text: "Cancel",
        action: onCancel,
        disabled: isConfirming,
        variant: "text"
      }
    ]}
    onClose={onCancel}
    maxWidth="sm"
    sx={{ zIndex: 10000 }}
  >
    {isConfirming && <LinearProgress sx={{ marginY: 2.5 }} color="secondary" />}
  </Dialog>
);
