import { useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { SitesSummaryListResponse } from "../../../types";
import { siteSummaryQueryKey } from "../query-cache";

export const useGetSitesSummaries = () => {
  const queryKey = siteSummaryQueryKey();

  const { isLoading, isError, data, error } = useQuery<SitesSummaryListResponse>({
    queryKey,
    queryFn: () => AcceleratorApi.Sites.getSummary()
  });

  return {
    loading: isLoading,
    isError,
    sitesSummary: data?.data || [],
    error
  };
};
