import { UseMutationOptions, useMutation } from "react-query";
import { AcceleratorApi } from "../../..";
import { HTTPError } from "../../../types";

export const useDeleteAssetPropertyNote = () => {
  const queryOptions: UseMutationOptions<void, HTTPError, string> = {
    mutationFn: (assetNoteId: string) => AcceleratorApi.AssetPropertyNotesApi.delete(assetNoteId)
  };
  const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation(queryOptions);

  return {
    deleteAssetPropertyNote: mutateAsync,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
