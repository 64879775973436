import { HeaderClassParams } from "ag-grid-community";

// This is utility to auto size all columns in grid
// Ag Grid provide autoSizeAllColumns utils but we will wanted to keep last column full width so this is custom utility
export const dataGridAutoSizeColumns = (params, skipHeader?) => {
  const allColumnIds = [];
  const columns = params.columnApi.getColumns();
  columns.forEach((column, i) => {
    if (column.length !== i + 1) {
      allColumnIds.push(column.getId());
    }
  });
  params.api.autoSizeColumns(allColumnIds, !!skipHeader);
};

export const headerClassWithHighlight = (params: HeaderClassParams, cls?: string) => {
  const focused = params.api.getFocusedCell()?.column?.getColId() === params.column.getColId();
  return focused ? `highlight-with-primary ${cls}` : cls;
};

export const columnTypes = {
  rightAlignedHeaderHighlight: {
    headerClass: (params: HeaderClassParams) =>
      headerClassWithHighlight(params, "ag-right-aligned-header")
  },
  headerHighlight: {
    headerClass: (params: HeaderClassParams) => headerClassWithHighlight(params)
  }
};
