import { useMutation, UseMutationOptions } from "react-query";
import { AcceleratorApi } from "../../../apis";
import { SteadyState } from "../../../types/accelerator/steady-state";

export function useCreateSteadyStateMutation(
  options: UseMutationOptions<unknown, unknown, SteadyState> = {}
) {
  return useMutation({
    mutationFn: AcceleratorApi.SteadyState.create,
    ...options
  });
}
