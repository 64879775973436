import { UseMutationOptions, useMutation, useQueryClient } from "react-query";

import { AcceleratorApi } from "../../..";
import {
  HTTPError,
  ProductionUnit,
  ProductionUnitListResponse,
  ProductionUnitResponse
} from "../../../types";
import { productionUnitsListQueryKey, productionUnitsQueryKey } from "../query-cache";

export const useCreateProductionUnit = () => {
  const queryClient = useQueryClient();

  const queryOptions: UseMutationOptions<
    ProductionUnitResponse,
    HTTPError,
    Partial<ProductionUnit>
  > = {
    mutationFn: (productionUnit) => AcceleratorApi.ProductionUnits.create(productionUnit),
    onSuccess: (data: ProductionUnitResponse) => {
      queryClient.setQueryData<ProductionUnitListResponse | undefined>(
        productionUnitsListQueryKey(),
        (currentState: ProductionUnitListResponse | undefined) => {
          if (!currentState) return undefined;
          return {
            ...currentState,
            data: [data.data, ...currentState.data]
          };
        }
      );
      queryClient.setQueryData(productionUnitsQueryKey(data.data.id), () => data);
    }
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createProductionUnit: mutateAsync,
    productionUnit: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
