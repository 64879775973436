import { UseQueryOptions, useQuery } from "react-query";
import { HTTPError, SiteDocumentResponse } from "../../../types";
import { CommonDataApi } from "../../..";
import { siteById } from "./query-cache";

/**
 * Get a site by site ID
 */
export const useGetSiteById = (siteId: string) => {
  const queryOptions: UseQueryOptions<SiteDocumentResponse, HTTPError> = {
    queryKey: siteById(siteId),
    queryFn: () => CommonDataApi.Sites.getOne(siteId),
    enabled: !!siteId
  };

  const { data, isLoading, isError, isSuccess } = useQuery(queryOptions);

  return {
    site: data?.data,
    loading: isLoading,
    isError,
    isSuccess
  };
};
