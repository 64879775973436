import { UseMutationOptions, useMutation } from "react-query";

import { AcceleratorApi } from "../../..";
import { AlarmMode, HTTPError, AlarmModeDocumentResponse } from "../../../types";

export const useCreateAlarmMode = () => {
  const queryOptions: UseMutationOptions<
    AlarmModeDocumentResponse,
    HTTPError,
    Partial<AlarmMode>
  > = {
    mutationFn: (Alarm) => AcceleratorApi.AlarmModes.create(Alarm)
  };

  const { mutateAsync, isLoading, isSuccess, isError, error, data } = useMutation(queryOptions);

  return {
    createAlarmMode: mutateAsync,
    alarmMode: data?.data,
    loading: isLoading,
    isSuccess,
    isError,
    error
  };
};
