import { UseQueryOptions, useQuery } from "react-query";

import { AcceleratorApi } from "../../..";
import { HTTPError, ProductionUnitListResponse } from "../../../types";
import { productionUnitsListQueryKey } from "../query-cache";

export const useGetProductionUnits = () => {
  const queryKey = productionUnitsListQueryKey();

  const queryOptions: UseQueryOptions<ProductionUnitListResponse, HTTPError> = {
    queryKey,
    queryFn: () => AcceleratorApi.ProductionUnits.get()
  };

  const { isLoading, isSuccess, isError, data, error, refetch, isFetching } =
    useQuery(queryOptions);

  return {
    loading: isLoading,
    isSuccess,
    isError,
    productionUnits: data?.data,
    error,
    isFetching,
    refetch
  };
};
